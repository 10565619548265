import { useEffect, useRef, useState } from 'react';

// ant design 
import { Col, Row } from 'antd';
import { Progress } from 'antd';
import { Button, Switch } from 'antd';
import { ReloadOutlined } from '@ant-design/icons'; // Ant Design reset icon


import { useMessageContext, MessageContext, CSMMisoMessage } from '../reducers/messageContext';
import { DeviceValue, RecordKey } from '../idb/idb';
import { Mutex, MutexInterface, withTimeout } from 'async-mutex';
import { CSM_PROTOCOL_COMMAND_TYPE, CSM_PROTOCOL_EVENT_TYPE, parseBiosensorsSignalEvent, parseEventPayload } from '../serial/csm';
import { v4 as uuidv4 } from 'uuid';
import { loadHumidityCalibrant, loadSpotsGrid1D } from '../localStorage';
import { DEFAULT_PLOT_DECIMATED_FPS, DEFAULT_STORAGE_DECIMATED_FPS, IDB_PARTITION_WINDOW_SIZE, PLOT_WINDOW_SIZE } from '../constants';
import { correctHumidity, processMziData } from '../analysis/compute';
import { SignatureWithSpotgrid } from '../types';


// types 

type BleOdorIdentificationProps = {};

enum SenseMode {
  Recording,
  Questionning,
}


const Monitoring: React.FC<BleOdorIdentificationProps> = () => {

  const { csmMessages, csmIsConnected, consumeCSMMessage, clearCSMMessages, addCSMCommand, hihValues } = useMessageContext();

  // * gauge tooling
  const MIN_VALUE = -7; // Define minimum value for the gauge
  const MAX_VALUE = 7;  // Define maximum value for the gauge
  const mapValueToPercent = (value: number): number => {
    // Map the value (-100 to 100) to a percentage (0 to 100)
    return ((value - MIN_VALUE) / (MAX_VALUE - MIN_VALUE)) * 100;
  };
  // *

  // * MZI and timestamps related useRefs
  const firstMZIsRef = useRef<number[] | null>(null);
  const previousMZIsRef = useRef<number[] | null>(null);
  const signalEnvelopeAvgRef = useRef<number>(0);
  const rawMZISeriesRef = useRef<number[][]>([]);
  const rawTimestampSeriesRef = useRef<number[]>([]);
  const lastDecimationTickRef = useRef<number>(0);

  // *

  // * device related useState
  const [deviceValue, setDeviceValue] = useState<DeviceValue | null>(null);
  const [currentSpotsgrid1d, setCurrentSpotsgrid1d] = useState<number[] | null>(null);
  // *

  // * humidity compensation 
  const [humidityCalibrant, setHumidityCalibrant] = useState<SignatureWithSpotgrid | undefined>(undefined);
  const humidityBaselineRef = useRef<number | null>(null);
  const [humidityCompensationEnabled, sethumidityCompensationEnabled] = useState<boolean>(true);
  // *

  //const [displayedIntensity, setDisplayedIntensity] = useState<number>(0);


  const [isLoading, setIsLoading] = useState<boolean>(true);

  // to be refactored ?
  const [rawFps, setRawFps] = useState<number>(0);
  const [decimatedFps, setDecimatedFps] = useState<number>(0);




  // mutex
  const messageQueueMutexRef = useRef<MutexInterface>(withTimeout(new Mutex(), 300));

  // start sampling when mounting the component
  useEffect(() => {
    clearCSMMessages();
    addCSMCommand({
      id: uuidv4().toString(),
      message: {
        CmdType: CSM_PROTOCOL_COMMAND_TYPE.StartSampling,
      },
    });
    setIsLoading(false);
    return () => {
      addCSMCommand({
        id: uuidv4().toString(),
        message: {
          CmdType: CSM_PROTOCOL_COMMAND_TYPE.StopSampling,
        },
      });
      setIsLoading(true);
    };
  }, []);

  // load humidity calibrant
  useEffect(() => {
    const hCalibrant = loadHumidityCalibrant()
    setHumidityCalibrant(hCalibrant)
    console.log("humidityCalibrant is", hCalibrant)
  }, [])

  // load spotgrid from local storage
  useEffect(() => {
    let _spotsgrid1d = loadSpotsGrid1D();
    if (!_spotsgrid1d) {
      console.log('sense page: spotsgrid1d is empty');
      return;
    }
    setCurrentSpotsgrid1d(_spotsgrid1d);
  }, []);
  useEffect(() => {
    if (csmMessages.length === 0 || messageQueueMutexRef.current.isLocked()) {
      return;
    }

    let t = Date.now();
    messageQueueMutexRef.current.acquire().then((release) => {
      let nFramesProcessed = 0;

      csmMessages.forEach((message) => {
        if (message.message.Type !== CSM_PROTOCOL_EVENT_TYPE.BiosensorsSignalEvent) {
          console.log('Non-biosensor event:', message.message);
          consumeCSMMessage(message.id);
          return;
        } else {
          nFramesProcessed++;
          consumeCSMMessage(message.id);

          // Parse message payload
          let mzis = parseMessagePayload(message);
          if (!mzis) return;

          // set Baseline delta H for future use
          if (humidityBaselineRef.current === null) {
            console.log("Changing humidity baseline reference as ", hihValues.humidity)
            humidityBaselineRef.current = hihValues.humidity
          }

          if (firstMZIsRef.current === null) {
            firstMZIsRef.current = [...mzis];
          }

          let averageMZI = processMziData(mzis, message.ts, lastDecimationTickRef.current, rawMZISeriesRef.current, firstMZIsRef.current, currentSpotsgrid1d, humidityCompensationEnabled, humidityCalibrant, humidityBaselineRef.current, hihValues)
          if (averageMZI !== undefined) {
            signalEnvelopeAvgRef.current = averageMZI
          }


        }
      });
      rawMZISeriesRef.current = [] // do we need this reset in monitoring mode ?
      // Release the mutex after processing
      release();
    }).catch((e) => {
      console.error('Mutex acquisition failed', e);
      messageQueueMutexRef.current.cancel();
      messageQueueMutexRef.current.release();
    });

    return () => {
      messageQueueMutexRef.current.cancel();
      messageQueueMutexRef.current.release();
    };
  }, [csmMessages]);

  // Helper Functions

  // Parse message payload to extract MZI data
  const parseMessagePayload = (message: CSMMisoMessage) => {
    try {
      let eventPayload = parseEventPayload(message.message.Payload);
      return parseBiosensorsSignalEvent(eventPayload.Data);
    } catch (e) {
      if (e instanceof Error) {
        console.error('Failed to parse biosensors event:', e.message);
      } else {
        console.error('Unknown error occurred while parsing biosensors event');
      }
      return null;
    }
  };


  useEffect(() => {
    const constructDeviceValue = async () => {
      let commonName = 'Neose CSM BLE';
      if (commonName === undefined || commonName === null) {
        commonName = '';
      }
      let shellSerial = '';
      let coreSensorSerial = '';
      let fwVersion = '';
      let hwVersion = '';
      let cameraExposure = 0;

      let spotsgrid = currentSpotsgrid1d;
      if (spotsgrid === undefined || spotsgrid === null) {
        throw new Error('spotsgrid is undefined');
      }

      let _deviceValue = {
        commonName,
        shellSerial,
        coreSensorSerial,
        fwVersion,
        hwVersion,
        cameraExposure,
        spotsgrid,
      };
      console.log('sense page: constructed device value', _deviceValue);
      return _deviceValue;
    };
    constructDeviceValue()
      .then((_deviceValue) => {
        setDeviceValue(_deviceValue);
      })
      .catch((e: any) => {
        console.log('sense page: could not construct device', e);
      });
  }, [currentSpotsgrid1d]);

  // reset MZI for display centered around 0
  const onClickReset = () => {
    // previousMZIsRef.current = null is to avoid synthetic phase jumps when signal drops from some value > pi/2 to zero
    previousMZIsRef.current = null;
    firstMZIsRef.current = null;
    humidityBaselineRef.current = null;
  };



  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>

      <h2> Average Intensity (rad) </h2>

      {/* Progress Gauge */}
      <Row justify="center" style={{ marginBottom: '30px' }}>
        <Col span={12} xs={24}>
          <Progress
            type="dashboard"
            percent={mapValueToPercent(parseFloat(signalEnvelopeAvgRef.current.toFixed(1)))}
            format={(percent) => `${parseFloat(signalEnvelopeAvgRef.current.toFixed(1))}`}
            strokeColor={parseFloat(signalEnvelopeAvgRef.current.toFixed(1)) >= 0 ? '#52c41a' : '#ff4d4f'} // Green for positive, red for negative, should be adapted with aryballe color
            width={250}  // Set the width of the gauge (increase this for a bigger gauge)

          />
          <Row justify="center">
            <Col span={20} style={{ padding: 20, borderRadius: 20, textAlign: 'center' }}>
              <p style={{ fontSize: 16, lineHeight: '22px' }}>Humidity: {hihValues.humidity.toFixed(1)}% Temperature: {hihValues.temperature.toFixed(1)} °C </p>
            </Col>



          </Row>

        </Col>
        <Col span={20} style={{ padding: 20, borderRadius: 20, textAlign: 'center' }}>
          <Button
            type="primary"
            icon={<ReloadOutlined />} // Add the reset icon
            onClick={onClickReset} // Trigger onClickReset when the button is clicked
          >

          </Button>
        </Col>
        <Col span={20} style={{ padding: 20, borderRadius: 20, textAlign: 'center' }}>
          <div>Humidity correction enabled</div>
          <Switch
            onChange={sethumidityCompensationEnabled} defaultChecked={humidityCompensationEnabled} // Trigger onClickReset when the button is clicked
          />
        </Col>
      </Row>


    </div >
  );
};

export default Monitoring;
