export interface QuestionningResult {
  label: string;
  point: [number, number];
  data?: Plotly.Data[];
  layout?: Partial<Plotly.Layout>;
}

export interface SignatureWithSpotgrid {
  signature: number[];
  spotsgrid: number[];
  deltaHumidity?: number;
  deltaTemperature?: number;
}

export enum SessionType {
  Records = 'Records',
  Models = 'Saved models',
}
