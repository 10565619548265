import { FC } from 'react';
import { FlexRow } from '../common';
import { colorTupleToRGBA } from '../utils';

export const ColormapWidget: FC<{
  cmap: Record<string, [number, number, number]>;
}> = ({ cmap }) => {
  return (
    <FlexRow
      style={{
        width: '100%',
        justifyContent: 'center',
      }}
    >
      {Object.entries(cmap).map(([label, color]) => {
        return (
          <FlexRow
            style={{
              gap: 3,
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: 20,
                height: 20,
                backgroundColor: colorTupleToRGBA(color, 0.2),
                borderColor: colorTupleToRGBA(color, 1),
                borderRadius: 5,
                borderWidth: 1,
                borderStyle: 'solid',
                display: 'inline-block',
              }}
            />
            <i>{label}</i>
          </FlexRow>
        );
      })}
    </FlexRow>
  );
};
