const issuer = 'https://auth.aryballe.com/oauth2/default';
const clientId = '0oa1e6sn0vPjqPS3W5d7';
const scopes = ['openid', 'profile', 'email', 'groups'];
const redirectUri = window.location.origin + '/signin/callback';
const postLogoutRedirectUri = window.location.origin + '/signout/callback';

export const oidcConfig = {
  issuer,
  clientId,
  scopes,
  redirectUri,
  postLogoutRedirectUri,
};
