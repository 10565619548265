/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from 'protobufjs/minimal';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots['default'] || ($protobuf.roots['default'] = {});

export const FspMetadata = ($root.FspMetadata = (() => {
  /**
   * Namespace FspMetadata.
   * @exports FspMetadata
   * @namespace
   */
  const FspMetadata = {};

  /**
   * ProtocolVersion enum.
   * @name FspMetadata.ProtocolVersion
   * @enum {number}
   * @property {number} NOT_USED=0 NOT_USED value
   * @property {number} VERSION=1 VERSION value
   */
  FspMetadata.ProtocolVersion = (function () {
    const valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = 'NOT_USED')] = 0;
    values[(valuesById[1] = 'VERSION')] = 1;
    return values;
  })();

  FspMetadata.PeakPosition = (function () {
    /**
     * Properties of a PeakPosition.
     * @memberof FspMetadata
     * @interface IPeakPosition
     * @property {number|null} [x] PeakPosition x
     * @property {number|null} [y] PeakPosition y
     */

    /**
     * Constructs a new PeakPosition.
     * @memberof FspMetadata
     * @classdesc Represents a PeakPosition.
     * @implements IPeakPosition
     * @constructor
     * @param {FspMetadata.IPeakPosition=} [properties] Properties to set
     */
    function PeakPosition(properties) {
      if (properties) for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * PeakPosition x.
     * @member {number} x
     * @memberof FspMetadata.PeakPosition
     * @instance
     */
    PeakPosition.prototype.x = 0;

    /**
     * PeakPosition y.
     * @member {number} y
     * @memberof FspMetadata.PeakPosition
     * @instance
     */
    PeakPosition.prototype.y = 0;

    /**
     * Creates a new PeakPosition instance using the specified properties.
     * @function create
     * @memberof FspMetadata.PeakPosition
     * @static
     * @param {FspMetadata.IPeakPosition=} [properties] Properties to set
     * @returns {FspMetadata.PeakPosition} PeakPosition instance
     */
    PeakPosition.create = function create(properties) {
      return new PeakPosition(properties);
    };

    /**
     * Encodes the specified PeakPosition message. Does not implicitly {@link FspMetadata.PeakPosition.verify|verify} messages.
     * @function encode
     * @memberof FspMetadata.PeakPosition
     * @static
     * @param {FspMetadata.IPeakPosition} message PeakPosition message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PeakPosition.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.x != null && Object.hasOwnProperty.call(message, 'x')) writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.x);
      if (message.y != null && Object.hasOwnProperty.call(message, 'y')) writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.y);
      return writer;
    };

    /**
     * Encodes the specified PeakPosition message, length delimited. Does not implicitly {@link FspMetadata.PeakPosition.verify|verify} messages.
     * @function encodeDelimited
     * @memberof FspMetadata.PeakPosition
     * @static
     * @param {FspMetadata.IPeakPosition} message PeakPosition message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PeakPosition.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PeakPosition message from the specified reader or buffer.
     * @function decode
     * @memberof FspMetadata.PeakPosition
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {FspMetadata.PeakPosition} PeakPosition
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PeakPosition.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.FspMetadata.PeakPosition();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.x = reader.uint32();
            break;
          }
          case 2: {
            message.y = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a PeakPosition message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof FspMetadata.PeakPosition
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {FspMetadata.PeakPosition} PeakPosition
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PeakPosition.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PeakPosition message.
     * @function verify
     * @memberof FspMetadata.PeakPosition
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PeakPosition.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected';
      if (message.x != null && message.hasOwnProperty('x')) if (!$util.isInteger(message.x)) return 'x: integer expected';
      if (message.y != null && message.hasOwnProperty('y')) if (!$util.isInteger(message.y)) return 'y: integer expected';
      return null;
    };

    /**
     * Creates a PeakPosition message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof FspMetadata.PeakPosition
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {FspMetadata.PeakPosition} PeakPosition
     */
    PeakPosition.fromObject = function fromObject(object) {
      if (object instanceof $root.FspMetadata.PeakPosition) return object;
      let message = new $root.FspMetadata.PeakPosition();
      if (object.x != null) message.x = object.x >>> 0;
      if (object.y != null) message.y = object.y >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a PeakPosition message. Also converts values to other types if specified.
     * @function toObject
     * @memberof FspMetadata.PeakPosition
     * @static
     * @param {FspMetadata.PeakPosition} message PeakPosition
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PeakPosition.toObject = function toObject(message, options) {
      if (!options) options = {};
      let object = {};
      if (options.defaults) {
        object.x = 0;
        object.y = 0;
      }
      if (message.x != null && message.hasOwnProperty('x')) object.x = message.x;
      if (message.y != null && message.hasOwnProperty('y')) object.y = message.y;
      return object;
    };

    /**
     * Converts this PeakPosition to JSON.
     * @function toJSON
     * @memberof FspMetadata.PeakPosition
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PeakPosition.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PeakPosition
     * @function getTypeUrl
     * @memberof FspMetadata.PeakPosition
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PeakPosition.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com';
      }
      return typeUrlPrefix + '/FspMetadata.PeakPosition';
    };

    return PeakPosition;
  })();

  FspMetadata.PeakMask = (function () {
    /**
     * Properties of a PeakMask.
     * @memberof FspMetadata
     * @interface IPeakMask
     * @property {FspMetadata.IPeakPosition|null} [peakBottomLeft] PeakMask peakBottomLeft
     * @property {FspMetadata.IPeakPosition|null} [peakTopRight] PeakMask peakTopRight
     * @property {number|null} [peakMaskAngle] PeakMask peakMaskAngle
     * @property {number|null} [peakMaskRadius] PeakMask peakMaskRadius
     */

    /**
     * Constructs a new PeakMask.
     * @memberof FspMetadata
     * @classdesc Represents a PeakMask.
     * @implements IPeakMask
     * @constructor
     * @param {FspMetadata.IPeakMask=} [properties] Properties to set
     */
    function PeakMask(properties) {
      if (properties) for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * PeakMask peakBottomLeft.
     * @member {FspMetadata.IPeakPosition|null|undefined} peakBottomLeft
     * @memberof FspMetadata.PeakMask
     * @instance
     */
    PeakMask.prototype.peakBottomLeft = null;

    /**
     * PeakMask peakTopRight.
     * @member {FspMetadata.IPeakPosition|null|undefined} peakTopRight
     * @memberof FspMetadata.PeakMask
     * @instance
     */
    PeakMask.prototype.peakTopRight = null;

    /**
     * PeakMask peakMaskAngle.
     * @member {number} peakMaskAngle
     * @memberof FspMetadata.PeakMask
     * @instance
     */
    PeakMask.prototype.peakMaskAngle = 0;

    /**
     * PeakMask peakMaskRadius.
     * @member {number} peakMaskRadius
     * @memberof FspMetadata.PeakMask
     * @instance
     */
    PeakMask.prototype.peakMaskRadius = 0;

    /**
     * Creates a new PeakMask instance using the specified properties.
     * @function create
     * @memberof FspMetadata.PeakMask
     * @static
     * @param {FspMetadata.IPeakMask=} [properties] Properties to set
     * @returns {FspMetadata.PeakMask} PeakMask instance
     */
    PeakMask.create = function create(properties) {
      return new PeakMask(properties);
    };

    /**
     * Encodes the specified PeakMask message. Does not implicitly {@link FspMetadata.PeakMask.verify|verify} messages.
     * @function encode
     * @memberof FspMetadata.PeakMask
     * @static
     * @param {FspMetadata.IPeakMask} message PeakMask message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PeakMask.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.peakBottomLeft != null && Object.hasOwnProperty.call(message, 'peakBottomLeft')) $root.FspMetadata.PeakPosition.encode(message.peakBottomLeft, writer.uint32(/* id 1, wireType 2 =*/ 10).fork()).ldelim();
      if (message.peakTopRight != null && Object.hasOwnProperty.call(message, 'peakTopRight')) $root.FspMetadata.PeakPosition.encode(message.peakTopRight, writer.uint32(/* id 2, wireType 2 =*/ 18).fork()).ldelim();
      if (message.peakMaskAngle != null && Object.hasOwnProperty.call(message, 'peakMaskAngle')) writer.uint32(/* id 3, wireType 5 =*/ 29).float(message.peakMaskAngle);
      if (message.peakMaskRadius != null && Object.hasOwnProperty.call(message, 'peakMaskRadius')) writer.uint32(/* id 4, wireType 0 =*/ 32).uint32(message.peakMaskRadius);
      return writer;
    };

    /**
     * Encodes the specified PeakMask message, length delimited. Does not implicitly {@link FspMetadata.PeakMask.verify|verify} messages.
     * @function encodeDelimited
     * @memberof FspMetadata.PeakMask
     * @static
     * @param {FspMetadata.IPeakMask} message PeakMask message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PeakMask.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PeakMask message from the specified reader or buffer.
     * @function decode
     * @memberof FspMetadata.PeakMask
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {FspMetadata.PeakMask} PeakMask
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PeakMask.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.FspMetadata.PeakMask();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.peakBottomLeft = $root.FspMetadata.PeakPosition.decode(reader, reader.uint32());
            break;
          }
          case 2: {
            message.peakTopRight = $root.FspMetadata.PeakPosition.decode(reader, reader.uint32());
            break;
          }
          case 3: {
            message.peakMaskAngle = reader.float();
            break;
          }
          case 4: {
            message.peakMaskRadius = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a PeakMask message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof FspMetadata.PeakMask
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {FspMetadata.PeakMask} PeakMask
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PeakMask.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PeakMask message.
     * @function verify
     * @memberof FspMetadata.PeakMask
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PeakMask.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected';
      if (message.peakBottomLeft != null && message.hasOwnProperty('peakBottomLeft')) {
        let error = $root.FspMetadata.PeakPosition.verify(message.peakBottomLeft);
        if (error) return 'peakBottomLeft.' + error;
      }
      if (message.peakTopRight != null && message.hasOwnProperty('peakTopRight')) {
        let error = $root.FspMetadata.PeakPosition.verify(message.peakTopRight);
        if (error) return 'peakTopRight.' + error;
      }
      if (message.peakMaskAngle != null && message.hasOwnProperty('peakMaskAngle')) if (typeof message.peakMaskAngle !== 'number') return 'peakMaskAngle: number expected';
      if (message.peakMaskRadius != null && message.hasOwnProperty('peakMaskRadius')) if (!$util.isInteger(message.peakMaskRadius)) return 'peakMaskRadius: integer expected';
      return null;
    };

    /**
     * Creates a PeakMask message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof FspMetadata.PeakMask
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {FspMetadata.PeakMask} PeakMask
     */
    PeakMask.fromObject = function fromObject(object) {
      if (object instanceof $root.FspMetadata.PeakMask) return object;
      let message = new $root.FspMetadata.PeakMask();
      if (object.peakBottomLeft != null) {
        if (typeof object.peakBottomLeft !== 'object') throw TypeError('.FspMetadata.PeakMask.peakBottomLeft: object expected');
        message.peakBottomLeft = $root.FspMetadata.PeakPosition.fromObject(object.peakBottomLeft);
      }
      if (object.peakTopRight != null) {
        if (typeof object.peakTopRight !== 'object') throw TypeError('.FspMetadata.PeakMask.peakTopRight: object expected');
        message.peakTopRight = $root.FspMetadata.PeakPosition.fromObject(object.peakTopRight);
      }
      if (object.peakMaskAngle != null) message.peakMaskAngle = Number(object.peakMaskAngle);
      if (object.peakMaskRadius != null) message.peakMaskRadius = object.peakMaskRadius >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a PeakMask message. Also converts values to other types if specified.
     * @function toObject
     * @memberof FspMetadata.PeakMask
     * @static
     * @param {FspMetadata.PeakMask} message PeakMask
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PeakMask.toObject = function toObject(message, options) {
      if (!options) options = {};
      let object = {};
      if (options.defaults) {
        object.peakBottomLeft = null;
        object.peakTopRight = null;
        object.peakMaskAngle = 0;
        object.peakMaskRadius = 0;
      }
      if (message.peakBottomLeft != null && message.hasOwnProperty('peakBottomLeft')) object.peakBottomLeft = $root.FspMetadata.PeakPosition.toObject(message.peakBottomLeft, options);
      if (message.peakTopRight != null && message.hasOwnProperty('peakTopRight')) object.peakTopRight = $root.FspMetadata.PeakPosition.toObject(message.peakTopRight, options);
      if (message.peakMaskAngle != null && message.hasOwnProperty('peakMaskAngle')) object.peakMaskAngle = options.json && !isFinite(message.peakMaskAngle) ? String(message.peakMaskAngle) : message.peakMaskAngle;
      if (message.peakMaskRadius != null && message.hasOwnProperty('peakMaskRadius')) object.peakMaskRadius = message.peakMaskRadius;
      return object;
    };

    /**
     * Converts this PeakMask to JSON.
     * @function toJSON
     * @memberof FspMetadata.PeakMask
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PeakMask.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PeakMask
     * @function getTypeUrl
     * @memberof FspMetadata.PeakMask
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PeakMask.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com';
      }
      return typeUrlPrefix + '/FspMetadata.PeakMask';
    };

    return PeakMask;
  })();

  FspMetadata.Calibration = (function () {
    /**
     * Properties of a Calibration.
     * @memberof FspMetadata
     * @interface ICalibration
     * @property {FspMetadata.IPeakMask|null} [peakMask] Calibration peakMask
     * @property {Array.<number>|null} [peakGains] Calibration peakGains
     * @property {Uint8Array|null} [peakOffsets] Calibration peakOffsets
     * @property {Array.<number>|null} [deprecatedSpotsGrid] Calibration deprecatedSpotsGrid
     * @property {Array.<number>|null} [spotsGrid] Calibration spotsGrid
     * @property {number|null} [cameraExposure] Calibration cameraExposure
     * @property {number|null} [vcselPower] Calibration vcselPower
     */

    /**
     * Constructs a new Calibration.
     * @memberof FspMetadata
     * @classdesc Represents a Calibration.
     * @implements ICalibration
     * @constructor
     * @param {FspMetadata.ICalibration=} [properties] Properties to set
     */
    function Calibration(properties) {
      this.peakGains = [];
      this.deprecatedSpotsGrid = [];
      this.spotsGrid = [];
      if (properties) for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * Calibration peakMask.
     * @member {FspMetadata.IPeakMask|null|undefined} peakMask
     * @memberof FspMetadata.Calibration
     * @instance
     */
    Calibration.prototype.peakMask = null;

    /**
     * Calibration peakGains.
     * @member {Array.<number>} peakGains
     * @memberof FspMetadata.Calibration
     * @instance
     */
    Calibration.prototype.peakGains = $util.emptyArray;

    /**
     * Calibration peakOffsets.
     * @member {Uint8Array} peakOffsets
     * @memberof FspMetadata.Calibration
     * @instance
     */
    Calibration.prototype.peakOffsets = $util.newBuffer([]);

    /**
     * Calibration deprecatedSpotsGrid.
     * @member {Array.<number>} deprecatedSpotsGrid
     * @memberof FspMetadata.Calibration
     * @instance
     */
    Calibration.prototype.deprecatedSpotsGrid = $util.emptyArray;

    /**
     * Calibration spotsGrid.
     * @member {Array.<number>} spotsGrid
     * @memberof FspMetadata.Calibration
     * @instance
     */
    Calibration.prototype.spotsGrid = $util.emptyArray;

    /**
     * Calibration cameraExposure.
     * @member {number} cameraExposure
     * @memberof FspMetadata.Calibration
     * @instance
     */
    Calibration.prototype.cameraExposure = 0;

    /**
     * Calibration vcselPower.
     * @member {number} vcselPower
     * @memberof FspMetadata.Calibration
     * @instance
     */
    Calibration.prototype.vcselPower = 0;

    /**
     * Creates a new Calibration instance using the specified properties.
     * @function create
     * @memberof FspMetadata.Calibration
     * @static
     * @param {FspMetadata.ICalibration=} [properties] Properties to set
     * @returns {FspMetadata.Calibration} Calibration instance
     */
    Calibration.create = function create(properties) {
      return new Calibration(properties);
    };

    /**
     * Encodes the specified Calibration message. Does not implicitly {@link FspMetadata.Calibration.verify|verify} messages.
     * @function encode
     * @memberof FspMetadata.Calibration
     * @static
     * @param {FspMetadata.ICalibration} message Calibration message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Calibration.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.peakMask != null && Object.hasOwnProperty.call(message, 'peakMask')) $root.FspMetadata.PeakMask.encode(message.peakMask, writer.uint32(/* id 1, wireType 2 =*/ 10).fork()).ldelim();
      if (message.peakGains != null && message.peakGains.length) {
        writer.uint32(/* id 2, wireType 2 =*/ 18).fork();
        for (let i = 0; i < message.peakGains.length; ++i) writer.uint32(message.peakGains[i]);
        writer.ldelim();
      }
      if (message.peakOffsets != null && Object.hasOwnProperty.call(message, 'peakOffsets')) writer.uint32(/* id 3, wireType 2 =*/ 26).bytes(message.peakOffsets);
      if (message.deprecatedSpotsGrid != null && message.deprecatedSpotsGrid.length) {
        writer.uint32(/* id 4, wireType 2 =*/ 34).fork();
        for (let i = 0; i < message.deprecatedSpotsGrid.length; ++i) writer.int32(message.deprecatedSpotsGrid[i]);
        writer.ldelim();
      }
      if (message.cameraExposure != null && Object.hasOwnProperty.call(message, 'cameraExposure')) writer.uint32(/* id 5, wireType 0 =*/ 40).uint32(message.cameraExposure);
      if (message.vcselPower != null && Object.hasOwnProperty.call(message, 'vcselPower')) writer.uint32(/* id 6, wireType 0 =*/ 48).uint32(message.vcselPower);
      if (message.spotsGrid != null && message.spotsGrid.length) {
        writer.uint32(/* id 7, wireType 2 =*/ 58).fork();
        for (let i = 0; i < message.spotsGrid.length; ++i) writer.sint32(message.spotsGrid[i]);
        writer.ldelim();
      }
      return writer;
    };

    /**
     * Encodes the specified Calibration message, length delimited. Does not implicitly {@link FspMetadata.Calibration.verify|verify} messages.
     * @function encodeDelimited
     * @memberof FspMetadata.Calibration
     * @static
     * @param {FspMetadata.ICalibration} message Calibration message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Calibration.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Calibration message from the specified reader or buffer.
     * @function decode
     * @memberof FspMetadata.Calibration
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {FspMetadata.Calibration} Calibration
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Calibration.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.FspMetadata.Calibration();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.peakMask = $root.FspMetadata.PeakMask.decode(reader, reader.uint32());
            break;
          }
          case 2: {
            if (!(message.peakGains && message.peakGains.length)) message.peakGains = [];
            if ((tag & 7) === 2) {
              let end2 = reader.uint32() + reader.pos;
              while (reader.pos < end2) message.peakGains.push(reader.uint32());
            } else message.peakGains.push(reader.uint32());
            break;
          }
          case 3: {
            message.peakOffsets = reader.bytes();
            break;
          }
          case 4: {
            if (!(message.deprecatedSpotsGrid && message.deprecatedSpotsGrid.length)) message.deprecatedSpotsGrid = [];
            if ((tag & 7) === 2) {
              let end2 = reader.uint32() + reader.pos;
              while (reader.pos < end2) message.deprecatedSpotsGrid.push(reader.int32());
            } else message.deprecatedSpotsGrid.push(reader.int32());
            break;
          }
          case 7: {
            if (!(message.spotsGrid && message.spotsGrid.length)) message.spotsGrid = [];
            if ((tag & 7) === 2) {
              let end2 = reader.uint32() + reader.pos;
              while (reader.pos < end2) message.spotsGrid.push(reader.sint32());
            } else message.spotsGrid.push(reader.sint32());
            break;
          }
          case 5: {
            message.cameraExposure = reader.uint32();
            break;
          }
          case 6: {
            message.vcselPower = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a Calibration message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof FspMetadata.Calibration
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {FspMetadata.Calibration} Calibration
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Calibration.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Calibration message.
     * @function verify
     * @memberof FspMetadata.Calibration
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Calibration.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected';
      if (message.peakMask != null && message.hasOwnProperty('peakMask')) {
        let error = $root.FspMetadata.PeakMask.verify(message.peakMask);
        if (error) return 'peakMask.' + error;
      }
      if (message.peakGains != null && message.hasOwnProperty('peakGains')) {
        if (!Array.isArray(message.peakGains)) return 'peakGains: array expected';
        for (let i = 0; i < message.peakGains.length; ++i) if (!$util.isInteger(message.peakGains[i])) return 'peakGains: integer[] expected';
      }
      if (message.peakOffsets != null && message.hasOwnProperty('peakOffsets')) if (!((message.peakOffsets && typeof message.peakOffsets.length === 'number') || $util.isString(message.peakOffsets))) return 'peakOffsets: buffer expected';
      if (message.deprecatedSpotsGrid != null && message.hasOwnProperty('deprecatedSpotsGrid')) {
        if (!Array.isArray(message.deprecatedSpotsGrid)) return 'deprecatedSpotsGrid: array expected';
        for (let i = 0; i < message.deprecatedSpotsGrid.length; ++i) if (!$util.isInteger(message.deprecatedSpotsGrid[i])) return 'deprecatedSpotsGrid: integer[] expected';
      }
      if (message.spotsGrid != null && message.hasOwnProperty('spotsGrid')) {
        if (!Array.isArray(message.spotsGrid)) return 'spotsGrid: array expected';
        for (let i = 0; i < message.spotsGrid.length; ++i) if (!$util.isInteger(message.spotsGrid[i])) return 'spotsGrid: integer[] expected';
      }
      if (message.cameraExposure != null && message.hasOwnProperty('cameraExposure')) if (!$util.isInteger(message.cameraExposure)) return 'cameraExposure: integer expected';
      if (message.vcselPower != null && message.hasOwnProperty('vcselPower')) if (!$util.isInteger(message.vcselPower)) return 'vcselPower: integer expected';
      return null;
    };

    /**
     * Creates a Calibration message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof FspMetadata.Calibration
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {FspMetadata.Calibration} Calibration
     */
    Calibration.fromObject = function fromObject(object) {
      if (object instanceof $root.FspMetadata.Calibration) return object;
      let message = new $root.FspMetadata.Calibration();
      if (object.peakMask != null) {
        if (typeof object.peakMask !== 'object') throw TypeError('.FspMetadata.Calibration.peakMask: object expected');
        message.peakMask = $root.FspMetadata.PeakMask.fromObject(object.peakMask);
      }
      if (object.peakGains) {
        if (!Array.isArray(object.peakGains)) throw TypeError('.FspMetadata.Calibration.peakGains: array expected');
        message.peakGains = [];
        for (let i = 0; i < object.peakGains.length; ++i) message.peakGains[i] = object.peakGains[i] >>> 0;
      }
      if (object.peakOffsets != null)
        if (typeof object.peakOffsets === 'string') $util.base64.decode(object.peakOffsets, (message.peakOffsets = $util.newBuffer($util.base64.length(object.peakOffsets))), 0);
        else if (object.peakOffsets.length >= 0) message.peakOffsets = object.peakOffsets;
      if (object.deprecatedSpotsGrid) {
        if (!Array.isArray(object.deprecatedSpotsGrid)) throw TypeError('.FspMetadata.Calibration.deprecatedSpotsGrid: array expected');
        message.deprecatedSpotsGrid = [];
        for (let i = 0; i < object.deprecatedSpotsGrid.length; ++i) message.deprecatedSpotsGrid[i] = object.deprecatedSpotsGrid[i] | 0;
      }
      if (object.spotsGrid) {
        if (!Array.isArray(object.spotsGrid)) throw TypeError('.FspMetadata.Calibration.spotsGrid: array expected');
        message.spotsGrid = [];
        for (let i = 0; i < object.spotsGrid.length; ++i) message.spotsGrid[i] = object.spotsGrid[i] | 0;
      }
      if (object.cameraExposure != null) message.cameraExposure = object.cameraExposure >>> 0;
      if (object.vcselPower != null) message.vcselPower = object.vcselPower >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a Calibration message. Also converts values to other types if specified.
     * @function toObject
     * @memberof FspMetadata.Calibration
     * @static
     * @param {FspMetadata.Calibration} message Calibration
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Calibration.toObject = function toObject(message, options) {
      if (!options) options = {};
      let object = {};
      if (options.arrays || options.defaults) {
        object.peakGains = [];
        object.deprecatedSpotsGrid = [];
        object.spotsGrid = [];
      }
      if (options.defaults) {
        object.peakMask = null;
        if (options.bytes === String) object.peakOffsets = '';
        else {
          object.peakOffsets = [];
          if (options.bytes !== Array) object.peakOffsets = $util.newBuffer(object.peakOffsets);
        }
        object.cameraExposure = 0;
        object.vcselPower = 0;
      }
      if (message.peakMask != null && message.hasOwnProperty('peakMask')) object.peakMask = $root.FspMetadata.PeakMask.toObject(message.peakMask, options);
      if (message.peakGains && message.peakGains.length) {
        object.peakGains = [];
        for (let j = 0; j < message.peakGains.length; ++j) object.peakGains[j] = message.peakGains[j];
      }
      if (message.peakOffsets != null && message.hasOwnProperty('peakOffsets'))
        object.peakOffsets = options.bytes === String ? $util.base64.encode(message.peakOffsets, 0, message.peakOffsets.length) : options.bytes === Array ? Array.prototype.slice.call(message.peakOffsets) : message.peakOffsets;
      if (message.deprecatedSpotsGrid && message.deprecatedSpotsGrid.length) {
        object.deprecatedSpotsGrid = [];
        for (let j = 0; j < message.deprecatedSpotsGrid.length; ++j) object.deprecatedSpotsGrid[j] = message.deprecatedSpotsGrid[j];
      }
      if (message.cameraExposure != null && message.hasOwnProperty('cameraExposure')) object.cameraExposure = message.cameraExposure;
      if (message.vcselPower != null && message.hasOwnProperty('vcselPower')) object.vcselPower = message.vcselPower;
      if (message.spotsGrid && message.spotsGrid.length) {
        object.spotsGrid = [];
        for (let j = 0; j < message.spotsGrid.length; ++j) object.spotsGrid[j] = message.spotsGrid[j];
      }
      return object;
    };

    /**
     * Converts this Calibration to JSON.
     * @function toJSON
     * @memberof FspMetadata.Calibration
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Calibration.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Calibration
     * @function getTypeUrl
     * @memberof FspMetadata.Calibration
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Calibration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com';
      }
      return typeUrlPrefix + '/FspMetadata.Calibration';
    };

    return Calibration;
  })();

  /**
   * Role enum.
   * @name FspMetadata.Role
   * @enum {number}
   * @property {number} Unknown=0 Unknown value
   * @property {number} FspFnfSolution=4 FspFnfSolution value
   */
  FspMetadata.Role = (function () {
    const valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = 'Unknown')] = 0;
    values[(valuesById[4] = 'FspFnfSolution')] = 4;
    return values;
  })();

  FspMetadata.MetadataSchema = (function () {
    /**
     * Properties of a MetadataSchema.
     * @memberof FspMetadata
     * @interface IMetadataSchema
     * @property {FspMetadata.Role|null} [role] MetadataSchema role
     * @property {string|null} [marketingName] MetadataSchema marketingName
     * @property {string|null} [shellSerial] MetadataSchema shellSerial
     * @property {string|null} [coreSensorSerial] MetadataSchema coreSensorSerial
     * @property {FspMetadata.ICalibration|null} [calibration] MetadataSchema calibration
     * @property {FspMetadata.ProtocolVersion|null} [version] MetadataSchema version
     */

    /**
     * Constructs a new MetadataSchema.
     * @memberof FspMetadata
     * @classdesc Represents a MetadataSchema.
     * @implements IMetadataSchema
     * @constructor
     * @param {FspMetadata.IMetadataSchema=} [properties] Properties to set
     */
    function MetadataSchema(properties) {
      if (properties) for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * MetadataSchema role.
     * @member {FspMetadata.Role} role
     * @memberof FspMetadata.MetadataSchema
     * @instance
     */
    MetadataSchema.prototype.role = 0;

    /**
     * MetadataSchema marketingName.
     * @member {string} marketingName
     * @memberof FspMetadata.MetadataSchema
     * @instance
     */
    MetadataSchema.prototype.marketingName = '';

    /**
     * MetadataSchema shellSerial.
     * @member {string} shellSerial
     * @memberof FspMetadata.MetadataSchema
     * @instance
     */
    MetadataSchema.prototype.shellSerial = '';

    /**
     * MetadataSchema coreSensorSerial.
     * @member {string} coreSensorSerial
     * @memberof FspMetadata.MetadataSchema
     * @instance
     */
    MetadataSchema.prototype.coreSensorSerial = '';

    /**
     * MetadataSchema calibration.
     * @member {FspMetadata.ICalibration|null|undefined} calibration
     * @memberof FspMetadata.MetadataSchema
     * @instance
     */
    MetadataSchema.prototype.calibration = null;

    /**
     * MetadataSchema version.
     * @member {FspMetadata.ProtocolVersion} version
     * @memberof FspMetadata.MetadataSchema
     * @instance
     */
    MetadataSchema.prototype.version = 0;

    /**
     * Creates a new MetadataSchema instance using the specified properties.
     * @function create
     * @memberof FspMetadata.MetadataSchema
     * @static
     * @param {FspMetadata.IMetadataSchema=} [properties] Properties to set
     * @returns {FspMetadata.MetadataSchema} MetadataSchema instance
     */
    MetadataSchema.create = function create(properties) {
      return new MetadataSchema(properties);
    };

    /**
     * Encodes the specified MetadataSchema message. Does not implicitly {@link FspMetadata.MetadataSchema.verify|verify} messages.
     * @function encode
     * @memberof FspMetadata.MetadataSchema
     * @static
     * @param {FspMetadata.IMetadataSchema} message MetadataSchema message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MetadataSchema.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.role != null && Object.hasOwnProperty.call(message, 'role')) writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.role);
      if (message.marketingName != null && Object.hasOwnProperty.call(message, 'marketingName')) writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.marketingName);
      if (message.shellSerial != null && Object.hasOwnProperty.call(message, 'shellSerial')) writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.shellSerial);
      if (message.coreSensorSerial != null && Object.hasOwnProperty.call(message, 'coreSensorSerial')) writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.coreSensorSerial);
      if (message.calibration != null && Object.hasOwnProperty.call(message, 'calibration')) $root.FspMetadata.Calibration.encode(message.calibration, writer.uint32(/* id 5, wireType 2 =*/ 42).fork()).ldelim();
      if (message.version != null && Object.hasOwnProperty.call(message, 'version')) writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.version);
      return writer;
    };

    /**
     * Encodes the specified MetadataSchema message, length delimited. Does not implicitly {@link FspMetadata.MetadataSchema.verify|verify} messages.
     * @function encodeDelimited
     * @memberof FspMetadata.MetadataSchema
     * @static
     * @param {FspMetadata.IMetadataSchema} message MetadataSchema message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MetadataSchema.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MetadataSchema message from the specified reader or buffer.
     * @function decode
     * @memberof FspMetadata.MetadataSchema
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {FspMetadata.MetadataSchema} MetadataSchema
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MetadataSchema.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.FspMetadata.MetadataSchema();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.role = reader.int32();
            break;
          }
          case 2: {
            message.marketingName = reader.string();
            break;
          }
          case 3: {
            message.shellSerial = reader.string();
            break;
          }
          case 4: {
            message.coreSensorSerial = reader.string();
            break;
          }
          case 5: {
            message.calibration = $root.FspMetadata.Calibration.decode(reader, reader.uint32());
            break;
          }
          case 6: {
            message.version = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a MetadataSchema message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof FspMetadata.MetadataSchema
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {FspMetadata.MetadataSchema} MetadataSchema
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MetadataSchema.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MetadataSchema message.
     * @function verify
     * @memberof FspMetadata.MetadataSchema
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MetadataSchema.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected';
      if (message.role != null && message.hasOwnProperty('role'))
        switch (message.role) {
          default:
            return 'role: enum value expected';
          case 0:
          case 4:
            break;
        }
      if (message.marketingName != null && message.hasOwnProperty('marketingName')) if (!$util.isString(message.marketingName)) return 'marketingName: string expected';
      if (message.shellSerial != null && message.hasOwnProperty('shellSerial')) if (!$util.isString(message.shellSerial)) return 'shellSerial: string expected';
      if (message.coreSensorSerial != null && message.hasOwnProperty('coreSensorSerial')) if (!$util.isString(message.coreSensorSerial)) return 'coreSensorSerial: string expected';
      if (message.calibration != null && message.hasOwnProperty('calibration')) {
        let error = $root.FspMetadata.Calibration.verify(message.calibration);
        if (error) return 'calibration.' + error;
      }
      if (message.version != null && message.hasOwnProperty('version'))
        switch (message.version) {
          default:
            return 'version: enum value expected';
          case 0:
          case 1:
            break;
        }
      return null;
    };

    /**
     * Creates a MetadataSchema message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof FspMetadata.MetadataSchema
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {FspMetadata.MetadataSchema} MetadataSchema
     */
    MetadataSchema.fromObject = function fromObject(object) {
      if (object instanceof $root.FspMetadata.MetadataSchema) return object;
      let message = new $root.FspMetadata.MetadataSchema();
      switch (object.role) {
        default:
          if (typeof object.role === 'number') {
            message.role = object.role;
            break;
          }
          break;
        case 'Unknown':
        case 0:
          message.role = 0;
          break;
        case 'FspFnfSolution':
        case 4:
          message.role = 4;
          break;
      }
      if (object.marketingName != null) message.marketingName = String(object.marketingName);
      if (object.shellSerial != null) message.shellSerial = String(object.shellSerial);
      if (object.coreSensorSerial != null) message.coreSensorSerial = String(object.coreSensorSerial);
      if (object.calibration != null) {
        if (typeof object.calibration !== 'object') throw TypeError('.FspMetadata.MetadataSchema.calibration: object expected');
        message.calibration = $root.FspMetadata.Calibration.fromObject(object.calibration);
      }
      switch (object.version) {
        default:
          if (typeof object.version === 'number') {
            message.version = object.version;
            break;
          }
          break;
        case 'NOT_USED':
        case 0:
          message.version = 0;
          break;
        case 'VERSION':
        case 1:
          message.version = 1;
          break;
      }
      return message;
    };

    /**
     * Creates a plain object from a MetadataSchema message. Also converts values to other types if specified.
     * @function toObject
     * @memberof FspMetadata.MetadataSchema
     * @static
     * @param {FspMetadata.MetadataSchema} message MetadataSchema
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MetadataSchema.toObject = function toObject(message, options) {
      if (!options) options = {};
      let object = {};
      if (options.defaults) {
        object.role = options.enums === String ? 'Unknown' : 0;
        object.marketingName = '';
        object.shellSerial = '';
        object.coreSensorSerial = '';
        object.calibration = null;
        object.version = options.enums === String ? 'NOT_USED' : 0;
      }
      if (message.role != null && message.hasOwnProperty('role')) object.role = options.enums === String ? ($root.FspMetadata.Role[message.role] === undefined ? message.role : $root.FspMetadata.Role[message.role]) : message.role;
      if (message.marketingName != null && message.hasOwnProperty('marketingName')) object.marketingName = message.marketingName;
      if (message.shellSerial != null && message.hasOwnProperty('shellSerial')) object.shellSerial = message.shellSerial;
      if (message.coreSensorSerial != null && message.hasOwnProperty('coreSensorSerial')) object.coreSensorSerial = message.coreSensorSerial;
      if (message.calibration != null && message.hasOwnProperty('calibration')) object.calibration = $root.FspMetadata.Calibration.toObject(message.calibration, options);
      if (message.version != null && message.hasOwnProperty('version'))
        object.version = options.enums === String ? ($root.FspMetadata.ProtocolVersion[message.version] === undefined ? message.version : $root.FspMetadata.ProtocolVersion[message.version]) : message.version;
      return object;
    };

    /**
     * Converts this MetadataSchema to JSON.
     * @function toJSON
     * @memberof FspMetadata.MetadataSchema
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MetadataSchema.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MetadataSchema
     * @function getTypeUrl
     * @memberof FspMetadata.MetadataSchema
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MetadataSchema.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com';
      }
      return typeUrlPrefix + '/FspMetadata.MetadataSchema';
    };

    return MetadataSchema;
  })();

  return FspMetadata;
})());

export { $root as default };
