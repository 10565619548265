import { Col, Divider, Empty, Row, Typography, theme } from 'antd';
import { FlexCol, FlexRow, Paper } from '../common';
import { FC, useEffect } from 'react';
import { useMetadataContext } from '../reducers/metadataContext';
import { CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import { RefDesignProtocol } from '../../ref_design_protocol';
import { useMessageContext } from '../reducers/messageContext';
import { ThimphuPumpControlWidget } from '../widgets/thimphuPumpControlWidget';
import { SerialConnectPage } from './serialConnectPage';
import { ThimphuHIHListenerWidget } from '../widgets/thimphuHIHWidget';
import { RefkitCalibrationWidget } from '../widgets/refkitCalibrationWidget';
import { SpotfileManagementWidget } from '../widgets/spotfileManagementWidget';
import { navigatorSupportsSerial } from '../serial/utils';
import { WebserialNotSupportedWidget } from '../widgets/webserialNotSupportedWidget';

export const SerialDevicePage: FC = () => {
  const {
    thimphuPlatformId,
    thimphuFspMetadata,
    thimphuMemInfos,

    refkitVersion,
    refkitCapabilities,
    refkitConfig,
  } = useMetadataContext();

  const { thimphuIsConnected, refkitIsConnected } = useMessageContext();

  useEffect(() => {
    console.log('device page: devices are connected', thimphuIsConnected, refkitIsConnected);
  }, [thimphuIsConnected, refkitIsConnected]);

  const {
    token: { colorSuccessText, colorInfoText, colorErrorText },
  } = theme.useToken();

  if (!navigatorSupportsSerial()) {
    return <WebserialNotSupportedWidget />;
  }

  // if (!refkitIsConnected) {
  //     return <ConnectPage/>
  // }

  // console.log('rendering device page')

  return (
    <>
      <Row gutter={[10, 10]} align={'stretch'}>
        {/* NeOseAdvance */}
        {thimphuIsConnected && (
          <Col xs={24}>
            <Paper>
              <FlexCol
                style={{
                  alignItems: 'start',
                  gap: 0,
                }}
              >
                <Typography.Title level={3}>{thimphuFspMetadata?.marketingName}</Typography.Title>
                <Typography.Text>
                  <b>NOA S/N:</b> {thimphuFspMetadata?.shellSerial}
                </Typography.Text>
                <Typography.Text>
                  <b>CoreSensor S/N:</b> {thimphuFspMetadata?.coreSensorSerial}
                </Typography.Text>
              </FlexCol>
            </Paper>
          </Col>
        )}
        {/* Refkit */}
        <Col xs={24} lg={12}>
          <Paper
            style={{
              height: '100%',
            }}
          >
            <Typography.Title level={3}>Core Sensor Module</Typography.Title>
            {refkitVersion && refkitVersion.deviceType ? (
              <span>
                <b>{refkitVersion.deviceType.toUpperCase()}</b> ver. <b>{refkitVersion.hwVersion}</b> [{refkitVersion.fwVersion}]
              </span>
            ) : null}
            <Divider />
            {refkitCapabilities ? (
              <FlexCol
                style={{
                  gap: 3,
                }}
              >
                <div>
                  <b>Camera:</b> {refkitCapabilities.hasCamera ? <CheckCircleFilled style={{ color: colorSuccessText }} /> : <CloseCircleOutlined style={{ color: colorErrorText }} />}
                </div>
                <div>
                  <b>CoreSensor</b> {refkitCapabilities.hasCoreSensor ? <CheckCircleFilled style={{ color: colorSuccessText }} /> : <CloseCircleOutlined style={{ color: colorErrorText }} />}
                </div>
              </FlexCol>
            ) : null}
            <Divider />
            {refkitConfig ? (
              <FlexCol
                style={{
                  gap: 3,
                }}
              >
                <div>
                  <b>Exposure:</b> {refkitConfig.cameraExposure}
                </div>
                <div>
                  <b>Frequency:</b> {refkitConfig.cameraFrequency}
                </div>
                <div>
                  <b>Mode:</b>{' '}
                  {(function () {
                    switch (refkitConfig.cameraResolution) {
                      case RefDesignProtocol.CameraResolution.Resolution1280x800:
                        return '1280x800';
                      case RefDesignProtocol.CameraResolution.Resolution640x400:
                        return '640x400';
                      default:
                        return 'Unknown';
                    }
                  })()}
                  {refkitConfig.cameraPixelDepth && `x${refkitConfig.cameraPixelDepth}`}
                </div>
              </FlexCol>
            ) : null}
            <Divider />
            {refkitConfig ? (
              <FlexCol
                style={{
                  gap: 3,
                }}
              >
                <div>
                  <b>Nb peaks (MMIs):</b> {refkitConfig.peakMasks?.length}
                </div>
                <div>
                  <b>Peak mask radius:</b> {refkitConfig.peakMasks?.[0]?.radius}
                </div>
              </FlexCol>
            ) : null}
          </Paper>
        </Col>
        {/* Thimphu */}
        <Col xs={24} lg={12}>
          <Paper
            style={{
              height: '100%',
            }}
          >
            <Typography.Title level={3}>Control Board</Typography.Title>
            {thimphuIsConnected ? (
              <FlexCol
                style={{
                  gap: 3,
                }}
              >
                {thimphuPlatformId ? (
                  <span>
                    <b>{thimphuPlatformId.id?.toUpperCase()}</b> ver. <b>{thimphuPlatformId.version}</b> [S/N {thimphuPlatformId.serialNumber}]
                  </span>
                ) : null}
                <Divider />
                <FlexCol
                  style={{
                    gap: 3,
                  }}
                >
                  <div>
                    <b>Hygrometer:</b> Honeywell HIH 6120
                  </div>
                  <div>
                    <b>Pump:</b> KNF
                  </div>
                  <div>
                    <b>Memory:</b> {thimphuMemInfos?.size} bytes
                  </div>
                </FlexCol>
                <Divider />
                <FlexRow
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    height: 80,
                  }}
                >
                  <ThimphuHIHListenerWidget />
                </FlexRow>
                <Divider />
                <FlexRow>
                  <ThimphuPumpControlWidget />
                </FlexRow>
              </FlexCol>
            ) : (
              <FlexCol
                style={{
                  height: '80%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    <div
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      Control board <b>is not present</b>.
                      <br />
                      Please use an <b>external pump</b>. Hygrometry is not available.
                    </div>
                  }
                />
              </FlexCol>
            )}
          </Paper>
        </Col>
        <Col xs={24}>
          <SpotfileManagementWidget />
        </Col>
        <Col xs={24}>
          <RefkitCalibrationWidget />
        </Col>
      </Row>
    </>
  );
};
