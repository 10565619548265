export const mmi2mzi = (mmi: number[]): number[] => {
  let mzi: number[] = [];
  for (let i = 0; i < mmi.length; i += 3) {
    let p1 = mmi[i];
    let p2 = mmi[i + 1];
    let p3 = mmi[i + 2];
    let it = 2 * p2 - p1 - p3;
    let qt = Math.sqrt(3) * (p1 - p3);
    let phase = -Math.atan2(qt, it);
    mzi.push(phase);
  }
  return mzi;
};

// returns corrected MZIs and new Ks
export const correctPhaseShifts = (newMZIs: number[], previousMZIs: number[], previousKs: number[]): [number[], number[]] => {
  let correctedMZIs: number[] = [];
  let newKs: number[] = [];
  for (let i = 0; i < newMZIs.length; i++) {
    let mzi = newMZIs[i];
    const previousMzi = previousMZIs[i];
    let k = previousKs[i];
    let diff = mzi - previousMzi;
    if (diff > Math.PI) {
      k -= 1;
      mzi -= 2 * Math.PI;
    } else if (diff < -Math.PI) {
      k += 1;
      mzi += 2 * Math.PI;
    }
    correctedMZIs.push(mzi);
    newKs.push(k);
  }
  return [correctedMZIs, newKs];
};
