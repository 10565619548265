import { DEFAULT_BAUD_RATE, DEFAULT_PURGE_TIMEOUT, DEFAULT_WRITE_LOCK_WAIT_TIMEOUT, HEADER } from './constants';
import { byteArrayToInt2, byteArrayToInt4, intToByteArray2, intToByteArray4 } from './utils';
import { crc16ccitt } from 'crc';

const _readIntoViewWithTimeout = async (port: SerialPort, view: DataView, timeout: number): Promise<[ArrayBuffer, number]> => {
  if (port.readable === null) {
    throw new Error('port not readable');
  }
  let reader = port.readable.getReader({ mode: 'byob' });
  let t = setTimeout(() => {
    reader.releaseLock();
  }, timeout);
  try {
    const { value } = await reader.read(view);
    clearTimeout(t);
    reader.releaseLock();
    if (value === undefined) {
      throw new Error('empty value');
    }
    return [value.buffer, value.byteLength];
  } catch (e: any) {
    if (e.message && e.message.includes('Releasing')) {
      throw new Error('timeout');
    }
    throw e;
  }
};

const _readIntoViewBlocking = async (reader: ReadableStreamBYOBReader, view: DataView): Promise<[ArrayBuffer, number]> => {
  try {
    const { value } = await reader.read(view);
    if (value === undefined) {
      throw new Error('empty value');
    }
    return [value.buffer, value.byteLength];
  } catch (e: any) {
    throw e;
  }
};

const _readNBytesWithTimeout = async (port: SerialPort, n: number, timeout: number): Promise<Uint8Array> => {
  if (port.readable === null) {
    throw new Error('port not readable');
  }
  let offset = 0;
  let buffer = new ArrayBuffer(n);
  while (offset < buffer.byteLength) {
    // console.log('reading into buffer', offset, buffer.byteLength)
    let view = new DataView(buffer, offset, buffer.byteLength - offset);
    try {
      let [newBuffer, bytesRead] = await _readIntoViewWithTimeout(port, view, timeout);
      buffer = newBuffer;
      offset += bytesRead;
    } catch (e: any) {
      if (e.message && e.message.includes('timeout')) {
        throw new Error(`timeout while trying to read ${n} bytes; only ${offset} bytes received`);
      }
      throw e;
    }
  }
  return new Uint8Array(buffer);
};

const _readNBytesBlocking = async (reader: ReadableStreamBYOBReader, n: number): Promise<Uint8Array> => {
  let offset = 0;
  let buffer = new ArrayBuffer(n);
  while (offset < buffer.byteLength) {
    let view = new DataView(buffer, offset, buffer.byteLength - offset);
    let [newBuffer, bytesRead] = await _readIntoViewBlocking(reader, view);
    buffer = newBuffer;
    offset += bytesRead;
  }
  return new Uint8Array(buffer);
};

export const composeTram = (payload: Uint8Array, lengthNBytes: number): Uint8Array => {
  var lengthBytes: Uint8Array;
  if (lengthNBytes === 2) {
    lengthBytes = intToByteArray2(payload.length);
  } else if (lengthNBytes === 4) {
    lengthBytes = intToByteArray4(payload.length);
  } else {
    throw new Error(`invalid lengthNBytes ${lengthNBytes}`);
  }
  let crc = crc16ccitt(payload);
  let crcBytes = intToByteArray2(crc);

  let buffer = new ArrayBuffer(HEADER.length + lengthBytes.length + payload.length + crcBytes.length);
  let tram = new Uint8Array(buffer);

  tram.set(HEADER, 0);
  tram.set(lengthBytes, HEADER.length);
  tram.set(payload, HEADER.length + lengthBytes.length);
  tram.set(crcBytes, HEADER.length + lengthBytes.length + payload.length);

  return tram;
};

export const writeWithTimeout = async (port: SerialPort, data: Uint8Array, timeout: number): Promise<void> => {
  if (port.writable === null) {
    console.log('port not writable');
    return;
  }
  // console.log('about to write: ', data)
  let t = setTimeout(() => {
    throw new Error('timeout while waiting for port to be unlocked');
  }, DEFAULT_WRITE_LOCK_WAIT_TIMEOUT);
  while (port.writable.locked) {
    console.log('waiting for port to be unlocked');
    await new Promise((r) => setTimeout(r, 100));
  }
  clearTimeout(t);
  let writer = port.writable.getWriter();
  let nWrote = 0;
  while (nWrote < data.length) {
    let t = setTimeout(() => {
      writer.releaseLock();
    }, timeout);
    try {
      let chunk = data.slice(nWrote, nWrote + 64);
      void (await writer.ready);
      void (await writer.write(chunk));
      console.debug('serial port: written bytes: ', chunk.length);
      clearTimeout(t);
      nWrote += 64;
    } catch (e: any) {
      if (e.message && e.message.includes('Releasing')) {
        throw new Error('timeout');
      }
      writer.releaseLock();
    }
  }
  writer.releaseLock();
};

export const purgeReadableStream = async (port: SerialPort, initialError: any): Promise<void> => {
  if (initialError.message && initialError.message.includes('port not readable')) {
    // do not purge if the port is not readable
    return;
  }
  if (port.readable === null) {
    // do not purge if the port is not readable
    return;
  }
  if (initialError.message && initialError.message.includes('timeout')) {
    // do not purge after a timeout
    return;
  }
  let reader = port.readable.getReader();
  let t = setTimeout(() => {
    reader.releaseLock();
  }, DEFAULT_PURGE_TIMEOUT);
  try {
    const { value, done } = await reader.read();
    if (done) {
      console.log(`purged ${value ? value.byteLength : 0} bytes until done is true after an error: `, initialError);
      return;
    }
    if (value === undefined) {
      console.log(`purged until empty value after an error: `, initialError);
      return;
    }
    clearTimeout(t);
    reader.releaseLock();
    console.log(`purged ${value.byteLength} bytes after an error: `, initialError);
    return;
  } catch (e: any) {
    if (e.message && e.message.includes('Releasing')) {
      console.log(`purged until timeout after an error: `, initialError);
      return;
    }
    console.log(`purge unexpected error: ${e} after the initial error: ${initialError}`);
  }
};

export const readPayloadWithTimeout = async (port: SerialPort, lengthNBytes: number, timeout: number): Promise<Uint8Array> => {
  console.log;
  console.debug('about to read payload with timeout', timeout);
  let headerAttempt = 1;
  // create a sliding view of the last 4 bytes
  let headerBytesSlidingView = new Uint8Array(4);
  // read the first 3 bytes
  let headerBytes = await _readNBytesWithTimeout(port, 3, timeout);
  // put them in the sliding view in the last 3 positions
  headerBytesSlidingView.set(headerBytes, 1);
  while (true) {
    // read the next byte
    let headerByte = await _readNBytesWithTimeout(port, 1, timeout);
    // shift the sliding view to the left by 1
    headerBytesSlidingView.copyWithin(0, 1);
    // put the new byte in the last position
    headerBytesSlidingView.set(headerByte, 3);
    // check if the sliding view matches the header
    if (headerBytesSlidingView[0] !== HEADER[0] || headerBytesSlidingView[1] !== HEADER[1] || headerBytesSlidingView[2] !== HEADER[2] || headerBytesSlidingView[3] !== HEADER[3]) {
      console.log(`invalid header, attempt #${headerAttempt}, continuing..`);
      headerAttempt++;
    } else {
      if (headerAttempt > 1) {
        console.log(`valid header after ${headerAttempt} attempts`);
      }
      break;
    }
  }

  let lengthBytes = await _readNBytesWithTimeout(port, lengthNBytes, timeout);
  var length: number;
  if (lengthNBytes === 2) {
    length = byteArrayToInt2(lengthBytes);
  } else if (lengthNBytes === 4) {
    length = byteArrayToInt4(lengthBytes);
  } else {
    throw new Error(`invalid lengthNBytes ${lengthNBytes}`);
  }
  // console.log('length bytes', lengthBytes, length)

  let payloadBytes = await _readNBytesWithTimeout(port, length, timeout);
  // console.log('payload bytes', payloadBytes)

  let crcBytes = await _readNBytesWithTimeout(port, 2, timeout);
  // console.log('crc bytes', crcBytes)
  let localCrc = crc16ccitt(payloadBytes);
  let remoteCrc = byteArrayToInt2(crcBytes);

  if (localCrc !== remoteCrc) {
    console.log(`Invalid CRC: local ${localCrc} [${intToByteArray2(localCrc)}] != remote ${remoteCrc} [${crcBytes}]`);
  }
  return payloadBytes;
};

export const readPayloadBlocking = async (reader: ReadableStreamBYOBReader, lengthNBytes: number): Promise<Uint8Array> => {
  let headerAttempt = 1;
  // create a sliding view of the last 4 bytes
  let headerBytesSlidingView = new Uint8Array(4);
  // read the first 3 bytes
  // Only this first request is blocking without timeout
  let headerBytes = await _readNBytesBlocking(reader, 3);
  // put them in the sliding view in the last 3 positions
  headerBytesSlidingView.set(headerBytes, 1);
  while (true) {
    // read the next byte
    let headerByte = await _readNBytesBlocking(reader, 1);
    // shift the sliding view to the left by 1
    headerBytesSlidingView.copyWithin(0, 1);
    // put the new byte in the last position
    headerBytesSlidingView.set(headerByte, 3);
    // check if the sliding view matches the header
    if (headerBytesSlidingView[0] !== HEADER[0] || headerBytesSlidingView[1] !== HEADER[1] || headerBytesSlidingView[2] !== HEADER[2] || headerBytesSlidingView[3] !== HEADER[3]) {
      console.log(`invalid header, attempt #${headerAttempt}, continuing..`);
      headerAttempt++;
    } else {
      if (headerAttempt > 1) {
        console.log(`valid header after ${headerAttempt} attempts`);
      }
      break;
    }
  }

  let lengthBytes = await _readNBytesBlocking(reader, lengthNBytes);
  var length: number;
  if (lengthNBytes === 2) {
    length = byteArrayToInt2(lengthBytes);
  } else if (lengthNBytes === 4) {
    length = byteArrayToInt4(lengthBytes);
  } else {
    throw new Error(`invalid lengthNBytes ${lengthNBytes}`);
  }
  // console.log('length bytes', lengthBytes, length)

  let payloadBytes = await _readNBytesBlocking(reader, length);
  // console.log('payload bytes', payloadBytes)

  let crcBytes = await _readNBytesBlocking(reader, 2);
  // console.log('crc bytes', crcBytes)
  let localCrc = crc16ccitt(payloadBytes);
  let remoteCrc = byteArrayToInt2(crcBytes);

  if (localCrc !== remoteCrc) {
    throw new Error(`Invalid CRC: local ${localCrc} [${intToByteArray2(localCrc)}] != remote ${remoteCrc} [${crcBytes}]`);
  }
  return payloadBytes;
};

export const requestPort = async (filters?: SerialPortFilter[]): Promise<SerialPort> => {
  if (!('serial' in navigator)) {
    throw new Error('WebSerial not supported');
  }
  try {
    let port = await navigator.serial.requestPort({
      filters,
    });
    try {
      void (await port.open({ baudRate: DEFAULT_BAUD_RATE }));
    } catch (e: any) {
      if (e.message && e.message.includes('port is already open')) {
        console.log('port is already open');
      } else {
        console.log(e);
      }
    }
    console.log(port);
    return port;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
