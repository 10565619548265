import { Menu, Row } from "antd";
import { useState } from "react";
import { SessionType } from "../types";
import { RecordsPage } from "./recordsPage";
import ModelsPage from "./ModelsPage";

type RecordsAndModelsPageProps = {}

const RecordsAndModelsPage: React.FC<RecordsAndModelsPageProps> = () => {
  const [sessionType, setSessionType] = useState<SessionType>(SessionType.Records);

  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <Menu defaultSelectedKeys={[sessionType]} mode="horizontal" style={{ backgroundColor: 'transparent', display: 'flex', justifyContent: 'center' }}>
          <Menu.Item key={SessionType.Records} onClick={() => setSessionType(SessionType.Records)}>
            {SessionType.Records}
          </Menu.Item>
          <Menu.Item key={SessionType.Models} onClick={() => setSessionType(SessionType.Models)}>
            {SessionType.Models}
          </Menu.Item>
        </Menu>
      </div>
      <Row>
        {sessionType === SessionType.Records ? <RecordsPage /> : <ModelsPage />}
      </Row>
    </div>
  );
}


export default RecordsAndModelsPage;