import { Col, Row } from 'antd';
import SectionPage from '../../widgets/section/SectionPage';
import { useEffect, useState } from 'react';
import { ModelType } from '../../byteio/model';
import { QuestionningResult } from '../../types';
import Plot from 'react-plotly.js';
import { loadModel } from '../../localStorage';
import { classifySignature } from '../../analysis/classifier';
import { DEFAULT_PLOTLY_CONFIG, DEFAULT_PLOTLY_LAYOUT } from '../../constants';
import { getSignatureFigure } from '../../analysis/figures';
import { signature } from '../../analysis/definitions'

type GenericDisplayProps = {
  result: QuestionningResult | null;
};

const GenericDisplay: React.FC<GenericDisplayProps> = ({ result}) => {

  

  return (
    <SectionPage>
      <Col span={24}>
        <Row justify="center" align="middle" style={{ height: '80vh' }}>
          
          {result?.data !== undefined && result?.layout !== undefined && <Plot
              data={result?.data}
              layout={result?.layout}
              config={DEFAULT_PLOTLY_CONFIG}
              style={{
                width: '100%',
                height: '100%',
                margin: 'auto',
              }}
              useResizeHandler
            />}


          </Row>
        
          

      </Col>
      
    </SectionPage>
  );
};

export default GenericDisplay;
