import { instanceOfModelType, ModelType } from '../byteio/model';
import { GenericModel, ModelCategory, OBJECTIVE_COMPARISON_DIFFERENT, OBJECTIVE_COMPARISON_SIMILAR } from './definitions';
import { isInsideEllipse } from './ellipse';
import { pcaTransform } from './pca';
import { euclideanDistance, mean } from './utils';

/*
Aryballe custom classificator
A point is a projection of a signature on a 2D space (PCA or DC+PCA)
If point is inside one of the optimized (scaled) ellipses -> classified as this group
If point outside any ellipse -> unknown
If point is inside >1 ellipses -> group assigned as least euclidean distance between these groups' barycenters
*/
export const classifyPoint = (groupedScaledEllipses: Record<string, number[]>, point: [number, number]): string => {
  const results: string[] = [];
  Object.entries(groupedScaledEllipses).forEach(([group, ellipse]) => {
    if (isInsideEllipse(ellipse, point)) {
      results.push(group);
    }
  });
  const centroids = Object.entries(groupedScaledEllipses).map(([group, [cx, cy, a, b, theta]]) => ({ group, centroid: [cx, cy] }));
  let name: string = 'unknown';
  if (results.length === 1) {
    name = results[0];
  } else if (results.length > 1) {
    const centroidDistances = centroids.map(({ group, centroid }) => ({ group, dist: euclideanDistance(point, centroid) }));

    var closestGroup: string = centroidDistances[0].group;
    var closestDistance: number = centroidDistances[0].dist;

    centroidDistances.slice(1).forEach(({ group, dist }) => {
      if (dist < closestDistance) {
        closestGroup = group;
      }
    });
    name = closestGroup;
  }
  return name;
};

// signature should be of the same form as used in the model creation
// e.g. negative spots excluded, aggregated, sorted and normalized
//
// returns [group, point]
export const classifySignature = (groupedScaledEllipses: Record<string, number[]>, pcaEigenvectors: number[][], signature: number[]): [string, [number, number]] => {
  const points = pcaTransform([signature], pcaEigenvectors, 2);
  let point: [number, number] = [points[0][0], points[0][1]];
  return [classifyPoint(groupedScaledEllipses, point), point];
};


export const classifySignatureComparison = (referenceSignatures: number[][], questionningSignature: number[], distanceThreshold: number = 0.015): string => {

  console.log("ref signatures are", referenceSignatures.map(x => x.map(y => y.toFixed(2))))

  let avgSig: number[] = [];
  for (let i = 0; i < referenceSignatures[0].length; i++) {
    avgSig.push(mean(referenceSignatures.map(x => x[i])));
  }
  console.log("mean ref signature is", avgSig.map(x => x.toFixed(2)))

  let distances = referenceSignatures.map(refSig => euclideanDistance(refSig, questionningSignature))
  let result = mean(distances)
  console.log("distances between ref and questionning signatures", distances.map(x => x.toFixed(2)))
  console.log("mean distance between ref and questionning signature", result.toFixed(2))


  if (result > distanceThreshold) {
    return OBJECTIVE_COMPARISON_DIFFERENT
  } else {
    return OBJECTIVE_COMPARISON_SIMILAR
  }
}

export const classifyIntensityComparison = (referenceIntensities: number[], questionningSignature: number[], intensityComparisonThreshold: number = 0.1): string => {

  let meanRefIntensity = mean(referenceIntensities)
  let questionningIntensity = mean(questionningSignature)

  console.log("reference intensities are ", referenceIntensities.map(x => x.toFixed(2)))
  console.log("mean reference intensity is", meanRefIntensity.toFixed(2))

  let relativeDifference = Math.abs(meanRefIntensity - questionningIntensity) / meanRefIntensity
  console.log("relative difference between ref and questionning intensities ", relativeDifference.toFixed(2))


  if (relativeDifference > intensityComparisonThreshold) {
    return OBJECTIVE_COMPARISON_DIFFERENT
  } else {
    return OBJECTIVE_COMPARISON_SIMILAR
  }
}


export const classifySignatureWithModel = (currentModel: ModelType | GenericModel, questionningSignature: number[]): [string, [number, number]] => {

  console.log('questionningSignature is', questionningSignature.map(x => x.toFixed(2)), "with intensity", mean(questionningSignature).toFixed(2))

  // handle default model with ellipses
  if (instanceOfModelType(currentModel)) {
    let [label, point] = classifySignature(currentModel.groupedScaledEllipses, currentModel.pcaEigenvectors, questionningSignature);
    return [label, point]
  } else {
    // handle generic models
    switch (currentModel.metadata.type) {

      case ModelCategory.ComparisonSignatures:
        if (currentModel.referencesSignatures === undefined) {
          console.log("no references signatures detected, aborting prediction")
          break;
        }
        return [
          classifySignatureComparison(currentModel.referencesSignatures, questionningSignature, currentModel.comparisonThreshold),
          [0, 0]
        ]

      case ModelCategory.ComparisonIntensities:
        if (currentModel.referenceIntensities === undefined) {
          console.log("no references intensities detected, aborting prediction")
          break;
        }
        return [
          classifyIntensityComparison(currentModel.referenceIntensities, questionningSignature, currentModel.comparisonThreshold),
          [0, 0]
        ]

    }
  }
  return ["", [0, 0]]
};
