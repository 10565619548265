import { DEFAULT_PLOT_DECIMATED_FPS } from '../constants';
import { RecordValue, RecordValueWithSensograms, SENSOR_NATURE } from '../idb/idb';
import { HIHvalues } from '../reducers/messageContext';
import { SignatureWithSpotgrid } from '../types';
import { mean, transpose } from './utils';

type signal = number[][]; // [sensor][time]
type signature = number[]; // [sensor]


const _extractSignature = (a: signal, baselineBounds: number[], plateauBounds: number[]): signature => {
  const baselineMeans = transpose(a.slice(...baselineBounds)).map((sig) => mean(sig));
  const analyteMeans = transpose(a.slice(...plateauBounds)).map((sig) => mean(sig));
  if (baselineMeans.length !== analyteMeans.length) {
    throw Error(`Number of sensors in baseline and plateau do not correspond: ${baselineMeans.length} vs ${analyteMeans.length}`);
  }
  const signature: number[] = [];
  for (let i = 0; i < analyteMeans.length; i++) {
    signature.push(analyteMeans[i] - baselineMeans[i]);
  }
  return signature;
};

const _extractDeltaSensor = (a: number[], baselineBounds: number[], plateauBounds: number[]): number | undefined => {
  
  if (a.length > 0) {
    const baselineMean = mean(a.slice(...baselineBounds))
    const analyteMean = mean(a.slice(...plateauBounds));
    return analyteMean - baselineMean;
  }

  return undefined
};

export const normalizeL2 = (a: number[]): number[] => {
  let d = a.map((cur) => Math.pow(cur, 2));
  let dd = d.reduce((acc, cur) => (acc += cur), 0);
  let ddd = Math.sqrt(dd);
  return a.map((cur) => cur / ddd);
};

export const computeSignature = (record: RecordValueWithSensograms) => {
  if (record.baselineStart === undefined || record.baselineEnd === undefined) {
    throw Error('Baseline bounds are undefined');
  }
  if (record.analyteStart === undefined || record.analyteEnd === undefined) {
    throw Error('Analyte bounds are undefined');
  }
  const baselineBounds = [record.baselineStart, record.baselineEnd];
  const analyteBounds = [record.analyteStart, record.analyteEnd];
  const a = record.sensogramSeries;
  const signature = _extractSignature(a, baselineBounds, analyteBounds);
  return signature;
};

export const computeDeltaSensor = (record: RecordValueWithSensograms, kind: SENSOR_NATURE) => {
  if (record.baselineStart === undefined || record.baselineEnd === undefined) {
    throw Error('Baseline bounds are undefined');
  }
  if (record.analyteStart === undefined || record.analyteEnd === undefined) {
    throw Error('Analyte bounds are undefined');
  }

  const baselineBounds = [record.baselineStart, record.baselineEnd];
  const analyteBounds = [record.analyteStart, record.analyteEnd];

  let a: number [] = []
  switch (kind) {
    case SENSOR_NATURE.Humidity:
      a = record.humiditySeries
      break;
    case SENSOR_NATURE.Temperature:
      a = record.temperatureSeries
      break;
    default:
      console.log('sensor kind unknown when computing delta sensor: ', kind);
  }

  const deltaSensor = _extractDeltaSensor(a, baselineBounds, analyteBounds)
  return deltaSensor
}

export const correctHumidity = (a: number[], deltaHumidity: number, spotgrid: number[], humidityCalibrant: SignatureWithSpotgrid): number[] => {

  if (humidityCalibrant.deltaHumidity !== undefined) {
    let dh = humidityCalibrant.deltaHumidity
    let affinities = Object.fromEntries(
      humidityCalibrant.spotsgrid.map((key, index) => [key, humidityCalibrant.signature[index] / dh]),
    );
    return a.map((x, idx) => x - deltaHumidity * affinities[spotgrid[idx]])
  }

  return []
}

/*
Sort each signature in an array in spotName ASC order
*/
export const sortSignature = (spotsgrid1d: number[], signature: signature): [number[], signature] => {
  const spotsWithValues: [number, number][] = [];
  for (let i = 0; i < signature.length; i++) {
    spotsWithValues.push([spotsgrid1d[i], signature[i]]);
  }
  // console.log('before sort', spotsWithValues)
  spotsWithValues.sort();
  // console.log('after sort', spotsWithValues)
  let sortedSpotsgrid1d = spotsWithValues.map((e) => e[0]);
  let sortedSignature = spotsWithValues.map((e) => e[1]);

  return [sortedSignature, sortedSpotsgrid1d];
};

export const getAggregatedSpotsgridIndicesMap = (spotsgrid1d: number[]): Record<number, number[]> => {
  let _aggregationIndicesMap: Record<number, number[]> = {};
  if (!spotsgrid1d) {
    console.log('sense page: spotsgrid is empty');
    return _aggregationIndicesMap;
  }
  // Aggregate MZIs by peptide
  for (let i = 0; i < spotsgrid1d.length; i++) {
    let aggKey = spotsgrid1d[i];
    if (_aggregationIndicesMap[aggKey] === undefined) {
      _aggregationIndicesMap[aggKey] = [];
    }
    _aggregationIndicesMap[aggKey].push(i);
  }
  return _aggregationIndicesMap;
};

export const aggregateSignature = (signature: signature, spotsgrid1d: number[]): [signature, number[]] => {
  const _aggregationIndicesMap = getAggregatedSpotsgridIndicesMap(spotsgrid1d);
  const aggregatedSignature: signature = [];
  const aggregatedSpotsgrid1d: number[] = [];
  for (const [aggKey, indices] of Object.entries(_aggregationIndicesMap)) {
    aggregatedSignature.push(mean(indices.map((i) => signature[i])));
    aggregatedSpotsgrid1d.push(parseInt(aggKey));
  }
  return [aggregatedSignature, aggregatedSpotsgrid1d];
};

export const aggregateSensogramSpans = (sensogramSpans: number[][], spotsgrid1d: number[]): [number[][], number[]] => {
  const _aggregationIndicesMap = getAggregatedSpotsgridIndicesMap(spotsgrid1d);
  const aggregatedSensogramSpans: number[][] = [];
  const aggregatedSpotsgrid1d: number[] = [];
  for (const [aggKey, indices] of Object.entries(_aggregationIndicesMap)) {
    let aggregatedSensogramSpan: number[] = [];
    for (let i = 0; i < sensogramSpans[0].length; i++) {
      aggregatedSensogramSpan.push(mean(indices.map((j) => sensogramSpans[j][i])));
    }
    aggregatedSensogramSpans.push(aggregatedSensogramSpan);
    aggregatedSpotsgrid1d.push(parseInt(aggKey));
  }
  return [aggregatedSensogramSpans, aggregatedSpotsgrid1d];
};

export const softmaxForDistances = (distances: { [key: string]: number }): { [key: string]: number } => {
  // Get the distance values and negate them
  const values = Object.values(distances).map(distance => -distance);

  // Find the maximum value for numerical stability
  const maxDistance = Math.max(...values);

  // Calculate the exponential of each element
  const expDistances = values.map(distance => Math.exp(distance - maxDistance));

  // Calculate the sum of exponentials
  const sumExpDistances = expDistances.reduce((sum, value) => sum + value, 0);

  // Calculate the softmax probabilities and round them
  const softmaxValues = expDistances.map(expValue => Math.round(100 * (expValue / sumExpDistances)));

  // Construct the result object with the same keys as the input
  const softmaxResult: { [key: string]: number } = {};
  Object.keys(distances).forEach((key, index) => {
    softmaxResult[key] = softmaxValues[index];

  
  });
  console.log("Softmax Probabilities:", softmaxResult);
  return softmaxResult;
}

/*
functions to handle MZI calculations
*/

// Process the MZI data and calculate averages
export const processMziData = (mzis: number[], timestamp: number, lastDecimationTickRef:number, rawMZISeriesRef:number[][], firstMZIsRef:number[] | null, currentSpotsgrid1d:number[]|null, humidityCompensationEnabled:boolean, humidityCalibrant: SignatureWithSpotgrid | undefined, humidityBaselineRef:number | null, hihValues: HIHvalues) => {
  if (!currentSpotsgrid1d) {
      console.log('Spots grid is empty');
      return;
  }
  
  rawMZISeriesRef.push(mzis);
  


  // Decimation logic
  if (shouldDecimate(timestamp, lastDecimationTickRef)) {
      
      // calculate decimated MZI
      //console.log(rawMZISeriesRef.current)
      let decimatedMzis = calculateDecimatedMzis(currentSpotsgrid1d, rawMZISeriesRef);
      
      //console.log('mzi before applying corrections:', decimatedMzis)
      if (decimatedMzis !== undefined) {
          // Apply baseline correction, humidity compensation
          let correctedMZI = applyCorrections(decimatedMzis, firstMZIsRef, currentSpotsgrid1d, humidityCompensationEnabled, humidityCalibrant, humidityBaselineRef, hihValues);
          
          // Finalize the signal envelope average
          let finalizedSignalEnvelope = finalizeSignalEnvelope(correctedMZI);
          return finalizedSignalEnvelope
      }
  }

};

// Determine if the data should be decimated. RD : and therefore the display update
const shouldDecimate = (timestamp: number, lastDecimationTickRef:number) => {
  if (timestamp - lastDecimationTickRef >= 1000 / DEFAULT_PLOT_DECIMATED_FPS) {
      lastDecimationTickRef = timestamp;
      return true;
  }
  return false;
};



// Calculate decimated MZI values by averaging over window size
const calculateDecimatedMzis = (currentSpotsgrid1d:number[]|null, rawMZISeriesRef:number[][]) => {

  if (currentSpotsgrid1d !== null) {
      let decimatedMzis = new Array(currentSpotsgrid1d.length).fill(0);

      rawMZISeriesRef.forEach((mziSeries) => {
          mziSeries.forEach((mzi, index) => {
              decimatedMzis[index] += mzi;
          });
      });

      return decimatedMzis.map((sum) => sum / rawMZISeriesRef.length);
  }
};

// Apply corrections like humidity compensation
const applyCorrections = (decimatedMzis: number[], firstMZIsRef:number[] | null, currentSpotsgrid1d:number[]|null, humidityCompensationEnabled:boolean, humidityCalibrant: SignatureWithSpotgrid | undefined, humidityBaselineRef:number | null, hihValues:HIHvalues ) => {
  
  //console.log('firstMZIsRef.current is:', firstMZIsRef.current)
  decimatedMzis.forEach((mzi, index) => {
      if (firstMZIsRef !== null) {
          decimatedMzis[index] -= firstMZIsRef[index];
      }
  });
  
  //console.log('humidityCompensationEnabled:', humidityCompensationEnabled)

  if (humidityCompensationEnabled && humidityCalibrant !== undefined && humidityBaselineRef !== null && currentSpotsgrid1d !== null) {
      return correctHumidity(decimatedMzis, hihValues.humidity - humidityBaselineRef, currentSpotsgrid1d, humidityCalibrant);
  } else {
      return decimatedMzis
  }
};

// Finalize the signal envelope average and update state
const finalizeSignalEnvelope = (decimatedMzis: number[]) => {
  let lastFrame: number[] = decimatedMzis;
  //console.log('last frame is', lastFrame)
  let lastFrameSum: number = 0;
  for (let i = 0; i < lastFrame.length; i++) {
      lastFrameSum += lastFrame[i];
  }
  //console.log('finalMZIsSeries is', finalMZIsSeries)
  // building signalEnvelopeAvgRef
  let signalEnvelopeAvgRef = lastFrameSum / lastFrame.length;

  return signalEnvelopeAvgRef

};