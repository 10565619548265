import { FC, useEffect, useState } from 'react';
import { RecordValueWithSensograms } from '../idb/idb';
import { aggregateSignature, computeSignature, normalizeL2, sortSignature } from '../analysis/compute';
import { getSignatureFigure } from '../analysis/figures';
import Plotly from 'plotly.js';
import Plot from 'react-plotly.js';
import { DEFAULT_PLOTLY_CONFIG, DEFAULT_PLOTLY_LAYOUT } from '../constants';

export const SingleSignatureFigure: FC<{
  record: RecordValueWithSensograms;
  shouldAggregate: boolean;
}> = ({ record, shouldAggregate }) => {
  const [plotlyData, setPlotlyData] = useState<Plotly.Data[]>([]);
  const [plotlyLayout, setPlotlyLayout] = useState<Partial<Plotly.Layout>>({});

  useEffect(() => {
    let _spotsgrid1d = record.device.spotsgrid;
    let _signature = computeSignature(record);

    let excludedSignature: number[] = [];
    let excludedSpotsgrid1d: number[] = [];
    for (let i = 0; i < _spotsgrid1d.length; i++) {
      let sensorInt = _spotsgrid1d[i];
      if (sensorInt > 1) {
        excludedSignature.push(_signature[i]);
        excludedSpotsgrid1d.push(sensorInt);
      }
    }

    let finalSignature: number[] = [];
    let finalSpotsgrid1d: number[] = [];

    if (shouldAggregate) {
      // aggregate by common spot name
      let [aggregatedSignature, aggregatedSpotsgrid1d] = aggregateSignature(excludedSignature, excludedSpotsgrid1d);
      finalSignature = aggregatedSignature;
      finalSpotsgrid1d = aggregatedSpotsgrid1d;
    } else {
      finalSignature = excludedSignature;
      finalSpotsgrid1d = excludedSpotsgrid1d;
    }

    let [sortedFinaleSignature, sortedFinalSpotsgrid1d] = sortSignature(finalSpotsgrid1d, finalSignature);

    let normalizedSortedAggregatedSignature = normalizeL2(sortedFinaleSignature);

    let { data, layout } = getSignatureFigure(sortedFinalSpotsgrid1d, normalizedSortedAggregatedSignature);

    layout = {
      ...DEFAULT_PLOTLY_LAYOUT,
      ...layout,
    };

    setPlotlyData(data);
    setPlotlyLayout(layout);
  }, [record.baselineStart, record.baselineEnd, record.analyteStart, record.analyteEnd, shouldAggregate]);

  return (
    <Plot
      data={plotlyData}
      layout={plotlyLayout}
      config={DEFAULT_PLOTLY_CONFIG}
      style={{
        width: '100%',
        height: '100%',
        margin: 'auto',
      }}
      useResizeHandler
    />
  );
};
