export const HEADER = [0x55, 0xaa, 0x55, 0xaa];

export const DEFAULT_READ_TIMEOUT = 1000;
export const DEFAULT_WRITE_TIMEOUT = 100;
export const DEFAULT_PURGE_TIMEOUT = 100;
export const DEFAULT_DETECT_TIMEOUT = 100;
export const DEFAULT_WRITE_LOCK_WAIT_TIMEOUT = 1000;
export const DEFAULT_READ_CALIBRATION_TIMEOUT = 10000;
export const DEFAULT_DEVICE_DETECTION_INTERVAL = 1000;
export const DEFAULT_BLOCKING_LOOP_UNEXPECTED_ERROR_WAIT = 1000;
export const DEFAULT_SYNC_READ_EXPECTED_KEY_TIMEOUT = 3000;

export const DEFAULT_BAUD_RATE = 57600;

export const THIMPHU_LENGHT_N_BYTES = 2;
export const REFKIT_LENGTH_N_BYTES = 4;

export const DEFAULT_PEAK_MASK_RADIUS = 8;

export const REFKIT_SERIAL_FILTER: SerialPortFilter = {
  usbVendorId: 0x0483,
  usbProductId: 0x5740,
};

export const THIMPHU_SERIAL_FILTER: SerialPortFilter = {
  usbVendorId: 0x2341,
  usbProductId: 0x8037,
};

export const TRIVALVE_SERIAL_FILTER: SerialPortFilter = {
  usbVendorId: 0x2341,
  usbProductId: 0x8037,
};

export const THIMPHU_MEMORY_TOTAL_LENGTH = 1016;
export const THIMPHU_MEMORY_READ_BUFFER = 128;
export const THIMPHU_MEMORY_WRITE_BUFFER = 128;

export const REFKIT_MEMORY_READ_BUFFER = 1024;

export const DEFAULT_THIMPHU_PUMP_POWER = 20;

export const DEFAULT_ODOR_PRESENCE_DEACTIVATION_PERCENT_OF_MAX_VALUE = 0.3;

export enum SetExtensions {
  //CoreSensor
  setCoreVersionFromPB = 'core_sensor_hw_ver',
  setVcselCurrentFromPB = 'vcsel_uA',
  setVcselModeFromPB = 'vcselMode',
  resetOVfromPB = 'reset_ov9281',
  setCameraRegister = 'camera_i2c',
  debugLedFromPB = 'debugLed',
  peakExtractAlgoFromPB = 'peakExtractAlgo',

  peakMaskOnImageFromPB = 'peakMaskOnImage',
  imageSourceFromPB = 'imageSource',

  toRamBufferFromPB = 'toRamBuffer',
  ramDecimationRateFromPB = 'ramDecimationRate',
  sramImageIn8bitsFromPB = 'sramImageIn8bits',
  dcmiCaptureRate = 'dcmiCaptureRate',

  peakOffsetFromPB = 'peakOffset',

  clearAlgoLogsFromPB = 'clearAlgoLogs',
  peaksData = 'peaksData',
  mziOffsetRequest = 'mziOffsetRequest',
  resetMziOffset = 'resetMziOffset',
  initImageProcessingState = 'initImageProcessingStateRequest',

  // Global
  saveFromPB = 'save',
  restoreFactoryFromPB = 'restoreFactory',
  setRegFromPB = 'reg',

  // SDS Heating
  setHeatModeFromPB = 'heatMode',
  setHeatPulseTimeFromPB = 'heatPulseTime',
  setHeatCycleTimeFromPB = 'heatCycleTime',
  setHeatMaxPowerFromPB = 'heatMaxPower',
  setHeatCycleCountFromPB = 'heatCycleCount',
  setHeatOnFromPB = 'heatOn',
  setHeatTempSetpointFromPB = 'heatTempSetpoint',
  setHeatKpFromPB = 'heatKp',
  setHeatKiFromPB = 'heatKi',
}

export enum GetExtensions {
  getAlgoLogsFromPB = 'algoLogs',
  getCornerPosFromPB = 'cornerPos',
  getPeakMaskGapFromPB = 'peakMaskGap',
  getSramImageIn8bitsFromPB = 'sramImageIn8bits',
  getRamDecimationRateFromPB = 'ramDecimationRate',
  getRamImageCountFromPB = 'sramImageCount',
  getRamImageOverflowFromPB = 'sramImageOverflow',
  getPeakInfo = 'peak.',
  getRegFromPB = 'reg',
  getPeakExtractPixelsMap = 'peakExtractPixelsMap',
  getSdsTemp = 'sdsTemp',
}
