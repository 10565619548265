import { EigenvalueDecomposition, Matrix, SingularValueDecomposition } from 'ml-matrix';
import { abs, argMax, euclideanDistance, getGroupedMap, meanAxis, sum } from './utils';
import { ellipse, simplifiedEllipse } from './definitions';

/*
Computes the statistical certainty ellipses around a cluster of points
Should be calculated for each group separately
Returns 5 parameters of an ellipsis: centerX, centerY, radiusX, radiusY, angle (counter-clockwise in radians)
*/
export const getGaussianEllipse = (a: number[][]): number[] => {
  const aMat = new Matrix(a);

  const nSamples = aMat.rows;

  const mean = meanAxis(a, 0) as number[];
  const [meanX, meanY] = mean;
  const meanMat = new Matrix([mean]);

  const diff = aMat.subRowVector(meanMat);

  const covar = diff.transpose().mmul(diff).div(nSamples);
  const e = new SingularValueDecomposition(covar);
  const eigval = e.diagonal;
  const eigvecR = e.rightSingularVectors;

  const signs = [];
  for (let i = 0; i < 2; i++) {
    const col = eigvecR.getRow(i);
    const am = argMax(abs(col));
    if (col[am] < 0) {
      signs.push(-1);
    } else {
      signs.push(1);
    }
  }
  // eigvecR = eigvecR.mulColumn(0, signs[0])
  // eigvecR = eigvecR.mulColumn(1, signs[1])

  // We can calculate the standard deviation this way, using only covariances
  // const stdevX = Math.sqrt(covar.get(0, 0) as number)
  // const stdevY = Math.sqrt(covar.get(1, 1) as number)

  // Or this way as described here:
  // https://scikit-learn.org/stable/auto_examples/mixture/plot_gmm.html

  eigval.sort((a, b) => a - b);
  const stdevX = Math.sqrt(2) * Math.sqrt(eigval[1]);
  const stdevY = Math.sqrt(2) * Math.sqrt(eigval[0]);

  const un = eigvecR.getRowVector(0);
  const ud = un.norm('frobenius');
  const u = un.div(ud).to1DArray();
  // let cos: number = u[0]
  // const angle = Math.atan2(u.get(0,1), u.get(0,0)) - Math.PI/2
  const angle = Math.atan2(u[1], u[0]); //- Math.PI/2
  // const angle = Math.acos(u[0]) - Math.PI/2
  // console.log('EigvecR', eigvecR, 'Angle', angle)
  return [meanX, meanY, stdevX, stdevY, angle];
};

/*
Simple counter-clockwise rotation
*/
const rotate = (x: number, y: number, theta: number): number[] => {
  const c = Math.cos(theta);
  const s = Math.sin(theta);
  const R = new Matrix([
    [c, -s],
    [s, c],
  ]);
  const point = new Matrix([[x], [y]]); // "Vertical" column-vector
  return R.mmul(point).to1DArray();
};

/*
An algorithm to tell whether a point is inside or outside of an ellipse
*/
export const isInsideEllipse = (ellipse: number[], point: number[]) => {
  // eslint-disable-next-line prefer-const
  let [cx, cy, a, b, theta] = ellipse;
  let [x, y] = point;

  let c: number;
  let p: number;
  if (a > b) {
    c = Math.sqrt(Math.pow(a, 2) - Math.pow(b, 2));
    p = 2 * a;
  } else {
    c = Math.sqrt(Math.pow(b, 2) - Math.pow(a, 2));
    p = 2 * b;
    theta += Math.PI / 2;
  }

  // Translation
  x -= cx;
  y -= cy;

  // Rotation
  [x, y] = rotate(x, y, -theta);

  // Foci rule: |PF1| + |PF2| = 2a
  // https://en.wikipedia.org/wiki/Ellipse
  const foci = [
    [-c, 0],
    [c, 0],
  ];
  const focalDistances = foci.map((focus) => euclideanDistance([x, y], focus));
  if (sum(focalDistances) <= p) {
    return true;
  } else {
    return false;
  }
};

export const simplifyEllipse = (group: string, cx: number, cy: number, a: number, b: number, theta: number): simplifiedEllipse => {
  let c: number;
  let p: number;
  if (a > b) {
    c = Math.sqrt(Math.pow(a, 2) - Math.pow(b, 2));
    p = 2 * a;
  } else {
    c = Math.sqrt(Math.pow(b, 2) - Math.pow(a, 2));
    p = 2 * b;
    theta += Math.PI / 2;
  }
  const focusA = rotate(cx - c, cy, theta);
  const focusB = rotate(cx + c, cy, theta);
  return {
    group,
    p,
    focusAx: focusA[0],
    focusAy: focusA[1],
    focusBx: focusB[0],
    focusBy: focusB[1],
  };
};

/*
Transform ellipse parameters into an SVG path
*/
export const getEllipseSVGPath = (cx: number, cy: number, a: number, b: number, theta: number): string => {
  const center: number[] = [cx, cy];

  // Compute shifts in both directions
  const longitudinal = [a * Math.cos(-theta), -a * Math.sin(-theta)];
  const latitudinal = [-b * Math.cos(Math.PI / 2 - theta), b * Math.sin(Math.PI / 2 - theta)];

  // See scheme for details on this notation
  // Ellipses extrema:
  const M = center.map((e, i) => e - longitudinal[i]);
  const N = center.map((e, i) => e + longitudinal[i]);

  // Cubic bezier curve anchors:
  const A = center.map((e, i) => e - longitudinal[i] + latitudinal[i] / 0.75);
  const B = center.map((e, i) => e + longitudinal[i] + latitudinal[i] / 0.75);
  const D = center.map((e, i) => e - longitudinal[i] - latitudinal[i] / 0.75);
  return `M ${M[0]},${M[1]} C ${A[0]},${A[1]} ${B[0]},${B[1]} ${N[0]},${N[1]} S ${D[0]},${D[1]} ${M[0]},${M[1]}`;
};

/*
Scales ellipse's rx and ry radii by a scale factor
Returns a copy of an ellipse
*/
const _scaleEllipse = (ellipse: number[], scale: number): number[] => {
  const [cx, cy, rx, ry, theta] = ellipse;
  return [cx, cy, rx * scale, ry * scale, theta];
};

/*
Scales ellipse's Rx radius by a scale factor
Returns a copy of an ellipse
*/
export const scaleEllipseRx = (ellipse: number[], scale: number): number[] => {
  const [cx, cy, rx, ry, theta] = ellipse;
  return [cx, cy, rx * scale, ry, theta];
};

/*
Scales ellipse's Ry radius by a scale factor
Returns a copy of an ellipse
*/
export const scaleEllipseRy = (ellipse: number[], scale: number): number[] => {
  const [cx, cy, rx, ry, theta] = ellipse;
  return [cx, cy, rx, ry * scale, theta];
};

const _optimizeEllipsesScaleFactor = (a: number[][], ellipses: number[][], maxScaleFactor: number, scaleFunc: (ellipse: number[], scale: number) => number[]): number => {
  const n = a.length;
  const m = ellipses.length;

  let sigma = maxScaleFactor;
  let lastArgErrorChange: number = 3;
  let lastGlobalEllipseClassificationError: number = n + 1;
  let globalEllipseClassificationError: number = n;
  for (; sigma > Math.sqrt(2); sigma -= 0.1) {
    const ellipseClassificationResults = new Matrix(n, m);
    a.forEach((proj, i) => {
      ellipses.forEach((ellipse, j) => {
        const scaledEll = scaleFunc(ellipse, sigma);
        const res = isInsideEllipse(scaledEll, proj) ? 1 : 0;
        ellipseClassificationResults.set(i, j, res);
      });
    });
    globalEllipseClassificationError = ellipseClassificationResults.to2DArray().reduce((acc, cur) => {
      if (sum(cur) !== 1) {
        acc++;
      }
      return acc;
    }, 0);
    if (globalEllipseClassificationError < lastGlobalEllipseClassificationError) {
      lastGlobalEllipseClassificationError = globalEllipseClassificationError;
      lastArgErrorChange = sigma;
    } else if (globalEllipseClassificationError > lastGlobalEllipseClassificationError) {
      break;
    }
  }
  console.log('Final Result. Errors:', globalEllipseClassificationError, 'Sigma Between:', sigma.toFixed(2), lastArgErrorChange.toFixed(2));
  return lastArgErrorChange;
};

export const getOptimizedEllipsesScaleFactorRx = (a: number[][], ellipses: number[][]): number => {
  return _optimizeEllipsesScaleFactor(a, ellipses, 6, scaleEllipseRx);
};

export const getOptimizedEllipsesScaleFactorRy = (a: number[][], ellipses: number[][]): number => {
  return _optimizeEllipsesScaleFactor(a, ellipses, 18, scaleEllipseRy);
};

export const scaleEllipsesSigma = (groupedEllipses: Record<string, number[]>, scaleFactorRx: number, scaleFactorRy: number) => {
  const groupedNewEllipses: Record<string, number[]> = {};
  Object.entries(groupedEllipses).forEach(([group, ellipse]) => {
    let newEllipse = scaleEllipseRx(ellipse, scaleFactorRx);
    newEllipse = scaleEllipseRy(newEllipse, scaleFactorRy);
    groupedNewEllipses[group] = newEllipse;
  });
  return groupedNewEllipses;
};

/*
Finds the optimum scale for the ellipses sot that number of classification errors is minimized.
The largest sigma value for the minimum erorr is taken
Sigma is searched between maxScaleFactor and sqrt(2) (in descending manner)

Returns the scaled ellipses
*/
export const optimizeEllipsesSigma = (a: number[][], groupedEllipses: Record<string, number[]>): [Record<string, number[]>, number, number] => {
  const ellipses = Object.values(groupedEllipses);

  const scaleFactorRx = getOptimizedEllipsesScaleFactorRx(a, ellipses);
  const scaleFactorRy = getOptimizedEllipsesScaleFactorRy(a, ellipses);

  let groupedNewEllipses = scaleEllipsesSigma(groupedEllipses, scaleFactorRx, scaleFactorRy);

  return [groupedNewEllipses, scaleFactorRx, scaleFactorRy];
};
