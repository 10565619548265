import { Typography, Button } from 'antd';
import { FC, useEffect, useState } from 'react';
import { FlexCol, FlexRow } from '../common';
import { navigatorSupportsSerial } from '../serial/utils';
import { SwapOutlined } from '@ant-design/icons';
import { refkitPortExists, requestRefkitPort } from '../serial/refkit';
import { requestThimphuPort, thimphuPortExists } from '../serial/thimphu';
import { useMessageContext } from '../reducers/messageContext';
import { Link } from 'react-router-dom';
import { PairAndConnectivityWidget } from '../widgets/pairAndConnectivityWidget';

export const SerialConnectPage: FC = () => {
  const { refkitIsConnected, thimphuIsConnected } = useMessageContext();

  const [refkitIsPaired, setRefkitIsPaired] = useState(false);
  const [thimphuIsPaired, setThimphuIsPaired] = useState(false);

  useEffect(() => {
    refkitPortExists().then((exists) => {
      console.log('connect page: refkit exists: ', exists);
      setRefkitIsPaired(exists);
    });
  }, []);

  useEffect(() => {
    thimphuPortExists().then((exists) => {
      console.log('connect page: thimphu exists: ', exists);
      setThimphuIsPaired(exists);
    });
  }, []);

  if (!navigatorSupportsSerial()) {
    return (
      <FlexCol
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          gap: 0,
        }}
      >
        <Typography.Title level={3}>Your browser does not support Web Serial</Typography.Title>
        <Typography.Text>
          Please use <b>Google Chrome</b>, <b>Microsoft Edge</b> or <b>Opera Browser</b>
        </Typography.Text>
      </FlexCol>
    );
  }

  return (
    <FlexCol
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        gap: 0,
      }}
    >
      <Typography.Title
        level={3}
        style={{
          marginBottom: 0,
        }}
      >
        {refkitIsConnected && thimphuIsConnected ? 'Device connected' : 'Device disconnected'}
      </Typography.Title>
      <Typography.Title
        level={5}
        style={{
          marginTop: 0,
          marginBottom: 30,
        }}
      >
        {refkitIsConnected && thimphuIsConnected ? 'You can use the app' : 'Connect NeOse Advance to start'}
      </Typography.Title>
      <FlexRow
        style={{
          justifyContent: 'center',
        }}
      >
        <PairAndConnectivityWidget
          name="CoreSensor Module"
          isConnected={refkitIsConnected}
          isPaired={refkitIsPaired}
          onPair={async () => {
            try {
              await requestRefkitPort();
              setRefkitIsPaired(true);
            } catch (e) {}
          }}
        />
        <PairAndConnectivityWidget
          name="Control Board"
          isConnected={thimphuIsConnected}
          isPaired={thimphuIsPaired}
          onPair={async () => {
            try {
              await requestThimphuPort();
              setThimphuIsPaired(true);
            } catch (e) {}
          }}
        />
      </FlexRow>
      <FlexCol
        style={{
          marginTop: 50,
          textAlign: 'center',
          maxWidth: '80%',
        }}
      >
        <i>
          <span>
            Hint: If you are using the app for the <b>first time</b>, click on <SwapOutlined /> button to your PC to the device
          </span>
          <br />
          <span>
            You should pair <b>one STM32 comport</b> as well as <b>two ArduinoMicro devices</b>
          </span>
        </i>
      </FlexCol>
      {refkitIsConnected && !thimphuIsConnected && (
        <FlexCol
          style={{
            marginTop: 50,
            textAlign: 'center',
            maxWidth: '80%',
          }}
        >
          <Link to="/device#spotfile">
            <Button type="primary" size="large">
              I am using a standalone Core Sensor Module, no need for Control Board!
            </Button>
          </Link>
          <i>Please set up the appropriate spotfile before you begin</i>
        </FlexCol>
      )}
    </FlexCol>
  );
};
