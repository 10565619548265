export const ARYBALLE_COLOR_PALETTE_EXTENDED: [number, number, number][] = [
  [32, 21, 65], // dark blue
  [117, 85, 234], // purple
  [46, 229, 220], // turquoise
  [0, 148, 239], // blue
  [255, 66, 129], // magenta
  [252, 73, 82], // red
  [253, 196, 65], // yellow

  // Brighter Declinations
  [74, 59, 108],
  [171, 131, 255],
  [152, 214, 212],
  [101, 196, 255],
  [255, 172, 176],
  [255, 127, 126],
  [229, 225, 168],

  // Darker Declinations
  [120, 114, 135],
  [59, 41, 183],
  [0, 178, 170],
  [0, 103, 188],
  [198, 0, 185],
  [194, 0, 41],
  [198, 148, 0],
];

export const getColormap = (labels: string[]): Record<string, [number, number, number]> => {
  const colormap: Record<string, [number, number, number]> = {};
  labels.sort((a, b) => a.localeCompare(b));
  labels.forEach((l, i) => {
    colormap[l] = ARYBALLE_COLOR_PALETTE_EXTENDED[i % ARYBALLE_COLOR_PALETTE_EXTENDED.length];
  });
  return colormap;
};
