import { Col, Row } from 'antd';
import SectionPage from '../../widgets/section/SectionPage';

type OdorAnalysisProps = {};

const OdorAnalysis: React.FC<OdorAnalysisProps> = () => {
  return (
    <SectionPage>
      <Col span={24}>
        <Row justify="center" align="middle" style={{ minHeight: '40vh' }}>
          <img src="/illustration_analysing.svg" alt="" style={{ width: 255, height: 'auto', maxHeight: '100%' }} />
        </Row>
        <Row justify="center">
          <Col span={20}>
            <h1 style={{ textAlign: 'center', fontSize: 20, lineHeight: '30px', marginTop: 20, marginBottom: 20, fontWeight: 'bold' }}>Processing</h1>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={20} style={{ borderRadius: 20, textAlign: 'center' }}>
            <p style={{ fontSize: 16, lineHeight: '22px' }}>Please wait while the odor is being identified</p>
          </Col>
        </Row>
      </Col>
    </SectionPage>
  );
};

export default OdorAnalysis;
