import { FspMetadata } from '../../fsp_metadata';
import { thimphuProtocol } from '../../thimphu_protocol';
import { loadPumpPower } from '../localStorage';
import {
  DEFAULT_BAUD_RATE,
  DEFAULT_DETECT_TIMEOUT,
  DEFAULT_READ_TIMEOUT,
  DEFAULT_SYNC_READ_EXPECTED_KEY_TIMEOUT,
  DEFAULT_WRITE_TIMEOUT,
  THIMPHU_LENGHT_N_BYTES,
  THIMPHU_MEMORY_READ_BUFFER,
  THIMPHU_MEMORY_TOTAL_LENGTH,
  THIMPHU_MEMORY_WRITE_BUFFER,
  THIMPHU_SERIAL_FILTER,
} from './constants';
import { composeTram, purgeReadableStream, readPayloadWithTimeout, requestPort, writeWithTimeout } from './serial';
import { readVarInt } from './utils';

export const requestThimphuPort = async (): Promise<SerialPort | undefined> => {
  return await requestPort([THIMPHU_SERIAL_FILTER]);
};

const _thimphuEncodeMsg = (hostMsg: thimphuProtocol.HostMsg): Uint8Array => {
  let payload = thimphuProtocol.HostMsg.encode(hostMsg).finish();
  let tram = composeTram(payload, THIMPHU_LENGHT_N_BYTES);
  return tram;
};

const _doSyncThimphuCommand = async (port: SerialPort, hostMsg: thimphuProtocol.HostMsg, expectedKey: keyof thimphuProtocol.PlatformMsg, readTimeout: number): Promise<thimphuProtocol.PlatformMsg> => {
  const view = _thimphuEncodeMsg(hostMsg);
  void (await writeWithTimeout(port, view, DEFAULT_WRITE_TIMEOUT));
  let expectedKeyTimeout = DEFAULT_SYNC_READ_EXPECTED_KEY_TIMEOUT;
  if (expectedKeyTimeout < readTimeout) {
    expectedKeyTimeout = readTimeout;
  }
  try {
    let t = setTimeout(() => {
      throw new Error(`thimphu sync command: expected key timeout: ${expectedKey}`);
    }, expectedKeyTimeout);
    while (true) {
      try {
        let payloadBytes = await readPayloadWithTimeout(port, THIMPHU_LENGHT_N_BYTES, readTimeout);
        let platformMsg = thimphuProtocol.PlatformMsg.decode(payloadBytes);
        if (platformMsg[expectedKey]) {
          console.log('thimphu sync command: received the expected key', platformMsg[expectedKey]);
          clearTimeout(t);
          return platformMsg;
        } else {
          console.log('thimphu sync command: received response without the expected key; continuing..', platformMsg);
        }
      } catch (e) {
        clearTimeout(t);
        throw e;
      }
    }
  } catch (e: any) {
    void (await purgeReadableStream(port, e));
    throw e;
  }
};

export const thimphuPortExists = async () => {
  let ports = await navigator.serial.getPorts();
  ports = ports.filter((p) => p.getInfo().usbVendorId === THIMPHU_SERIAL_FILTER.usbVendorId && p.getInfo().usbProductId === THIMPHU_SERIAL_FILTER.usbProductId);
  console.log('thimphuPorts', ports);
  if (ports.length > 0) {
    return true;
  }
  return false;
};

export const detectThimphuPort = async (): Promise<SerialPort | undefined> => {
  if (!('serial' in navigator)) {
    throw new Error('WebSerial not supported');
  }
  let thimphuPort: SerialPort | undefined = undefined;
  try {
    let ports = await navigator.serial.getPorts();
    ports = ports.filter((p) => p.getInfo().usbVendorId === THIMPHU_SERIAL_FILTER.usbVendorId && p.getInfo().usbProductId === THIMPHU_SERIAL_FILTER.usbProductId);

    for (let port of ports) {
      try {
        await port.open({ baudRate: DEFAULT_BAUD_RATE });
      } catch {}
      let msg = thimphuProtocol.HostMsg.create({
        getPlatformId: {},
      });
      try {
        let platformId = await _doSyncThimphuCommand(port, msg, 'platformId', DEFAULT_DETECT_TIMEOUT);
        console.log('thimphu detection: platformId', platformId);
        thimphuPort = port;
      } catch (e: any) {
        console.log('thimphu detection: failed to get platformId', e.message);
        console.log('it is probably the internal sampler; will try to write on D10 to open the valve..');
        try {
          // Report version
          void (await writeWithTimeout(port, new Uint8Array([0xf9]), DEFAULT_WRITE_TIMEOUT));
          // Start Sysex
          void (await writeWithTimeout(port, new Uint8Array([0xf0, 0x79, 0xf7]), DEFAULT_WRITE_TIMEOUT));
          void (await writeWithTimeout(port, new Uint8Array([0xf0, 0x6b, 0xf7]), DEFAULT_WRITE_TIMEOUT));
          void (await writeWithTimeout(port, new Uint8Array([0xf0, 0x69, 0xf7]), DEFAULT_WRITE_TIMEOUT));
          // PinMode: OUTPUT on D10
          void (await writeWithTimeout(port, new Uint8Array([0xf4, 0x0a, 0x01]), DEFAULT_WRITE_TIMEOUT));
          // DigitalWrite: HIGH on D10
          void (await writeWithTimeout(port, new Uint8Array([0x91, 0x04, 0x00]), DEFAULT_WRITE_TIMEOUT));
          console.log('thimphu detection: wrote on D10');
        } catch (e: any) {
          console.log('thimphu detection: failed to write on D10', e.message);
        }
        continue;
      }
    }
  } catch (e) {
    throw e;
  }
  return thimphuPort;
};

const _thimphuReadSyncFspMetadata = async (port: SerialPort): Promise<FspMetadata.MetadataSchema> => {
  // get mem infos
  let msg = thimphuProtocol.HostMsg.create({
    getMemInfos: {},
  });
  let memInfosMsg = await _doSyncThimphuCommand(port, msg, 'memInfos', DEFAULT_READ_TIMEOUT);
  if (!memInfosMsg.memInfos || !memInfosMsg.memInfos.rdBufferSize || !memInfosMsg.memInfos.size) {
    throw new Error('thimphu read sync fsp metadata: memInfos is empty');
  }

  let offset = 0;
  let readOnceSize: number = memInfosMsg.memInfos.rdBufferSize;
  let totalSize: number = memInfosMsg.memInfos.size;

  let varIntMsgLenSize = 0;
  let varIntMsgLen = 0;

  let bufferView = new Uint8Array(totalSize);

  while (offset < totalSize) {
    if (offset + readOnceSize > totalSize) {
      readOnceSize = totalSize - offset;
    }
    let msg = thimphuProtocol.HostMsg.create({
      readMem: {
        offset,
        size: readOnceSize,
      },
    });
    let readMemMsg = await _doSyncThimphuCommand(port, msg, 'readMem', DEFAULT_READ_TIMEOUT);
    if (readMemMsg.readMem && readMemMsg.readMem.data && readMemMsg.readMem.data.length > 0) {
      bufferView.set(readMemMsg.readMem.data, offset);
    }
    [varIntMsgLen, varIntMsgLenSize] = readVarInt(bufferView.slice(1));
    if (offset > 1 + varIntMsgLenSize + varIntMsgLen) {
      totalSize = 1 + varIntMsgLenSize + varIntMsgLen;
    }
    // console.debug(`fspMetadata read: offset ${offset}, size ${readOnceSize}, totalLength ${totalSize}; varIntMsgLen ${varIntMsgLen}, varIntMsgLenSize ${varIntMsgLenSize}`)
    offset += readOnceSize;
  }
  let payload = bufferView.slice(1 + varIntMsgLenSize, 1 + varIntMsgLenSize + varIntMsgLen);
  // console.debug('fspMetadata payload', payload)
  let fspMetadata = FspMetadata.MetadataSchema.decode(payload);
  return fspMetadata;
};

export const initializeThimphu = async (
  port: SerialPort
): Promise<{
  platformId: thimphuProtocol.IPlatformIdResult;
  memInfos: thimphuProtocol.IMemInfosResult;
  fspMetadata: FspMetadata.IMetadataSchema;
}> => {
  let msg: thimphuProtocol.HostMsg;

  // get platform id
  msg = thimphuProtocol.HostMsg.create({
    getPlatformId: {},
  });
  let platformMsg = await _doSyncThimphuCommand(port, msg, 'platformId', DEFAULT_READ_TIMEOUT);
  if (!platformMsg.platformId) {
    throw new Error('thimphu init: platformId is empty');
  }

  // get mem infos
  msg = thimphuProtocol.HostMsg.create({
    getMemInfos: {},
  });
  let memInfosMsg = await _doSyncThimphuCommand(port, msg, 'memInfos', DEFAULT_READ_TIMEOUT);
  if (!memInfosMsg.memInfos || !memInfosMsg.memInfos.rdBufferSize || !memInfosMsg.memInfos.size) {
    throw new Error('thimphu init: memInfos is empty');
  }

  // start HIH
  try {
    msg = thimphuProtocol.HostMsg.create({
      start: {
        deviceId: thimphuProtocol.DeviceId.HIH6120_1,
      },
    });
    void (await _doSyncThimphuCommand(port, msg, 'status', DEFAULT_WRITE_TIMEOUT));
  } catch (e: any) {
    console.error('thimphu init: failed to start HIH', e.message);
  }

  // start pump with default power
  try {
    let pumpPower = loadPumpPower();

    let pwm = Math.floor((255 * pumpPower) / 100);
    msg = thimphuProtocol.HostMsg.create({
      start: {
        deviceId: thimphuProtocol.DeviceId.MOTOR_PUMP_1,
        motorPump: {
          pwm,
        },
      },
    });
    void (await _doSyncThimphuCommand(port, msg, 'status', DEFAULT_WRITE_TIMEOUT));
  } catch (e: any) {
    console.error('thimphu init: failed to start pump', e.message);
  }

  // read fsp metadata memory
  let fspMetadata = await _thimphuReadSyncFspMetadata(port);
  return {
    platformId: platformMsg.platformId,
    memInfos: memInfosMsg.memInfos,
    fspMetadata: fspMetadata,
  };
};

export const thimphuGetPlatformId = async (port: SerialPort) => {
  let msg = thimphuProtocol.HostMsg.create({
    getPlatformId: {},
  });
  const view = _thimphuEncodeMsg(msg);
  void (await writeWithTimeout(port, view, DEFAULT_WRITE_TIMEOUT));
};

export const thimphuGetMemInfos = async (port: SerialPort) => {
  let msg = thimphuProtocol.HostMsg.create({
    getMemInfos: {},
  });
  const view = _thimphuEncodeMsg(msg);
  void (await writeWithTimeout(port, view, DEFAULT_WRITE_TIMEOUT));
};

export const thimphuSetPump = async (port: SerialPort, power: number) => {
  let pwm = Math.floor((255 * power) / 100);
  console.log('pwm', pwm);
  let msg = thimphuProtocol.HostMsg.create({
    start: {
      deviceId: thimphuProtocol.DeviceId.MOTOR_PUMP_1,
      motorPump: {
        pwm,
      },
    },
  });
  const view = _thimphuEncodeMsg(msg);
  void (await writeWithTimeout(port, view, DEFAULT_WRITE_TIMEOUT));
};

export const thimphuSetHIH = async (port: SerialPort, state: boolean) => {
  let msg: thimphuProtocol.HostMsg;
  if (state) {
    msg = thimphuProtocol.HostMsg.create({
      start: {
        deviceId: thimphuProtocol.DeviceId.HIH6120_1,
      },
    });
  } else {
    msg = thimphuProtocol.HostMsg.create({
      stop: {
        deviceId: thimphuProtocol.DeviceId.HIH6120_1,
      },
    });
  }
  const view = _thimphuEncodeMsg(msg);
  void (await writeWithTimeout(port, view, DEFAULT_WRITE_TIMEOUT));
};

const _thimphuReadMemory = async (port: SerialPort, offset: number, size: number) => {
  let msg = thimphuProtocol.HostMsg.create({
    readMem: {
      offset,
      size,
    },
  });
  const view = _thimphuEncodeMsg(msg);
  void (await writeWithTimeout(port, view, DEFAULT_WRITE_TIMEOUT));
};

export const thimphuReadMemory = async (port: SerialPort, offset: number) => {
  let size = THIMPHU_MEMORY_READ_BUFFER;
  let length = THIMPHU_MEMORY_TOTAL_LENGTH;
  if (offset + size > length) {
    size = length - offset;
  }
  return await _thimphuReadMemory(port, offset, size);
};

export const thimphuWriteMemory = async (port: SerialPort, offset: number, data: Uint8Array) => {
  if (data.byteLength > THIMPHU_MEMORY_WRITE_BUFFER) {
    throw new Error(`thimphu write memory: data size ${data.byteLength} exceeds the maximum size ${THIMPHU_MEMORY_WRITE_BUFFER}`);
  }
  let msg = thimphuProtocol.HostMsg.create({
    writeMem: {
      offset: offset + 1, // leave the first byte untouched (???)
      data,
      memId: thimphuProtocol.MemId.EEPROM,
    },
  });
  const tram = _thimphuEncodeMsg(msg);
  void (await writeWithTimeout(port, tram, DEFAULT_WRITE_TIMEOUT));
};

export const thimphuWriteFspMetadata = async (port: SerialPort, fspMetadata: FspMetadata.IMetadataSchema) => {
  let payload = FspMetadata.MetadataSchema.encodeDelimited(fspMetadata).finish();
  let offset = 1; // leave the first byte untouched (???)
  let length = payload.byteLength;
  let size = THIMPHU_MEMORY_WRITE_BUFFER;
  for (offset = 0; offset < length; offset += size) {
    let data = payload.slice(offset, offset + size);
    await thimphuWriteMemory(port, offset, data);
  }
};
