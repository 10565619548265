import { SaveOutlined, CloudUploadOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import { Row, Typography, Col, Radio, InputNumber, Button, message as antdMessage, RadioChangeEvent, Input } from 'antd';
import { FC, useState, useEffect, useRef } from 'react';
import { Paper, FlexCol } from '../common';
import { deleteModel } from '../localStorage';
import { GenericModel, Metadata, ModelCategory } from '../analysis/definitions';
import { putModel } from '../idb/idb';
import { DEFAULT_ANALYSIS_COMPARISON_THRESHOLD, DEFAULT_IMMEDIATE_RECOGNITION_BACKWARD_WINDOW_SEC } from '../constants';

const DEFAULT_MODEL_CATEGORY = ModelCategory.ComparisonSignatures

const generatePassFailModel = (modelCategory: ModelCategory, referenceSignatures: number[][], referenceIntensities: number[], threshold: number, spotgrid: number[], name: string, analyteDuration: number): GenericModel => {

  const metadata: Metadata = {
    ID: name,
    created_at: Date(),
    spotfile: spotgrid.map(x => x.toString()),
    type: modelCategory,
    debug: name.startsWith("DEBUG")
  }

  let modelPayload: GenericModel = {
    metadata: metadata,
    comparisonThreshold: threshold,
    analyteDuration: analyteDuration
  }

  switch (modelCategory) {
    case ModelCategory.ComparisonSignatures:
      modelPayload.referencesSignatures = referenceSignatures
      break;
    case ModelCategory.ComparisonIntensities:
      modelPayload.referenceIntensities = referenceIntensities
      break;
  }
  return modelPayload
}


export const ModelingWidget: FC<{
  signatures: number[][];
  intensities: number[];
  spotsgrid1d: number[];
}> = ({ signatures, intensities, spotsgrid1d }) => {

  const [modelCategory, setModelCategory] = useState<ModelCategory>(DEFAULT_MODEL_CATEGORY);
  const [passFailThreshold, setPassFailThreshold] = useState<number>(DEFAULT_ANALYSIS_COMPARISON_THRESHOLD);
  const [analyteDuration, setAnalyteDuration] = useState<number>(DEFAULT_IMMEDIATE_RECOGNITION_BACKWARD_WINDOW_SEC);
  const [modelName, setModelName] = useState<string>("")

  return (
    <Paper>
      <FlexCol style={{ gap: 7 }}>
        <Row justify={"center"}>
          <Typography.Title level={4}>Generate Pass Fail Models</Typography.Title>
        </Row>
        <Row justify="space-around" style={{ width: '100%' }} gutter={[20, 20]} >
          <Col xs={24} style={{ marginBottom: 20 }} md={12}>
            <Row align="middle">
              <Col xs={8}>Model Name</Col>
              <Col xs={16}>
                <Input
                  placeholder='Type a model name'
                  value={modelName}
                  onChange={(e) => setModelName(e.target.value)}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} style={{ marginBottom: 20 }} md={12}>
            <Row align="middle">
              <Col xs={8}>Model Type</Col>
              <Col xs={16}>
                <Radio.Group onChange={(e: RadioChangeEvent) => { setModelCategory(e.target.value) }} defaultValue={DEFAULT_MODEL_CATEGORY}>
                  <Radio.Button value={ModelCategory.ComparisonSignatures}>Signatures</Radio.Button>
                  <Radio.Button value={ModelCategory.ComparisonIntensities}>Intensities</Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-around" style={{ width: '100%' }} gutter={[20, 20]} >
          <Col xs={24} style={{ marginBottom: 20 }} md={12}>
            <Row align="middle">
              <Col xs={8}>Threshold</Col>
              <Col xs={16}>
                <InputNumber
                  min={0}
                  value={passFailThreshold}
                  onChange={(value) => {
                    if (value !== null) {
                      setPassFailThreshold(value)
                    }
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} style={{ marginBottom: 20 }} md={12}>
            <Row align="middle">
              <Col xs={8}>Analyte Duration (s)</Col>
              <Col xs={16}>
                <InputNumber
                  min={0}
                  value={analyteDuration}
                  onChange={(value) => {
                    if (value !== null) {
                      setAnalyteDuration(value)
                    }
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          justify={{
            xs: 'center',
            lg: 'end',
          }}
          gutter={[10, 10]}
        >
          <Col>
            <Button
              block
              type="primary"
              onClick={async () => {
                if (passFailThreshold !== null && modelName !== "") {
                  let model = generatePassFailModel(modelCategory, signatures, intensities, passFailThreshold, spotsgrid1d, modelName, analyteDuration);
                  await putModel(model)
                  antdMessage.success('Model saved!');
                } else {
                  antdMessage.error("Couldn't save model. Please fill all the fields");
                }
              }
              }
              icon={<SaveOutlined />}
            >
              Save Model
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => {
                console.log("uploaded !");
                antdMessage.success('Model uploaded to the cloud !');
              }}
              icon={<CloudUploadOutlined />}
            />
          </Col>
          <Col>
            <Button
              onClick={() => {
                if (passFailThreshold !== null && modelName !== "") {
                  let model = generatePassFailModel(modelCategory, signatures, intensities, passFailThreshold, spotsgrid1d, modelName, analyteDuration);
                  navigator.clipboard.writeText(JSON.stringify(model, null, 4));
                  antdMessage.success('Model copied to clipboard!');
                } else {
                  antdMessage.error("Couldn't save model. Please fill all the fields");
                }
              }
              }
              icon={<CopyOutlined />}
            />
          </Col>
        </Row>
      </FlexCol>
    </Paper >
  );
};