import { FC, useState, useEffect } from 'react';
import { useMessageContext } from '../reducers/messageContext';
import { getBleService, navigatorSupportsWebBle, requestBleDevice } from '../ble/ble';
import { Button, Typography, Select } from 'antd';
import { FlexCol, FlexRow } from '../common';
import { PairAndConnectivityWidget } from '../widgets/pairAndConnectivityWidget';
import { SwapOutlined } from '@ant-design/icons';
import { colorPalette } from '../widgets/color';
import { WebBleNotSupportedWidget } from '../widgets/webBleNotSupportedWidget';
import { GenericModel, Barycenters } from '../analysis/definitions';
import { saveGenericModel } from '../localStorage';
import { getAllModelKeys, putModel, getModel } from '../idb/idb';
import { message as antdMessage } from 'antd';

const MODEL_ENDPOINT = 'https://gitlab.com/api/v4/projects/Aryballe%2Falgorithms%2Fmodel-library/repository';
const MODEL_TOKEN = 'glpat-xxm-gFbsq5yad24xQYNg';


interface ModelFile {
  fileName: string;
  data: GenericModel | undefined;
}
// Declare require.context
declare const require: {
  context: (directory: string, useSubdirectories: boolean, regExp: RegExp) => {
    keys: () => string[];
    (id: string): any;
  };
};

const fetchModelList = async (): Promise<string[]> => {
  let page = 1;
  let modelFileList: string[] = [];
  try {
    while (true) {
      const _modelFileList = await _fecthModelListPage(page);
      if (_modelFileList.length === 0) {
        break;
      }
      modelFileList = modelFileList.concat(_modelFileList);
      page++;
    }
  } catch (error) {
    console.log("Error fetching online models, using local models")
    modelFileList = await getAllModelKeys()
  }
  return modelFileList;
};

const _fecthModelListPage = async (page: number): Promise<string[]> => {
  const response = await fetch(`${MODEL_ENDPOINT}/tree?path=model_data&access_token=${MODEL_TOKEN}&page=${page}&per_page=100`);
  const modelFileList: any[] = await response.json();
  return modelFileList.map(file => file.name.replace(".json", ""), "")  // trim extension
};

const fetchModel = async (modelName: string): Promise<GenericModel> => {
  try {
    const response = await fetch(`${MODEL_ENDPOINT}/files/model_data%2F${modelName}.json/raw?access_token=${MODEL_TOKEN}`);
    var model: GenericModel = await response.json();
  } catch (error) {
    console.log(`Error fetching model ${modelName} online, using local version of this model`)
    let potentialModel = await getModel(modelName)
    if (potentialModel !== undefined) {
      var model = potentialModel
    } else {
      throw new Error(`Couldn't fetch model: model ${modelName} doesn't exist locally`)
    }
  }
  model.metadata.ID = modelName
  console.log(model)
  await putModel(model)
  return model
};


const fetchLocalModel = async (modelName: string): Promise<GenericModel> => {

  let model = await getModel(modelName)
  if (model === undefined) {
    throw new Error(`Couldn't fetch model: model ${modelName} doesn't exist locally`)
  }
  model.metadata.ID = modelName
  console.log(model)
  return model
};


export const BleConnectPage: FC = () => {
  const [isPaired, setIsPaired] = useState(false);
  const [loading, setLoading] = useState(true);
  const [availableModels, setAvailableModels] = useState<ModelFile[]>([]);
  const [error, setError] = useState<string | null>(null);



  useEffect(() => {
    const fetchData = async () => {
      try {

        const model_names = await getAllModelKeys()
        const models = model_names.map(fileName => {
          const data = undefined
          return { fileName, data };
        })

        console.log(models)

        setAvailableModels(models);
        console.log('all models: ', models);
        setLoading(false);
      } catch (error) {
        //setError(error.message);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const { csmIsConnected, csmBleDevice, setCSMBleDevice, setCSMIsConnected } = useMessageContext();

  if (!navigatorSupportsWebBle()) {
    return <WebBleNotSupportedWidget />;
  }


  return (
    <FlexCol
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        gap: 0,
      }}
    >
      <Typography.Title
        level={3}
        style={{
          marginBottom: 0,
        }}
      >
        {csmIsConnected ? 'Device connected' : 'Device disconnected'}
      </Typography.Title>
      <Typography.Title
        level={5}
        style={{
          marginTop: 0,
          marginBottom: 30,
        }}
      >
        {csmIsConnected ? 'You can use the app' : 'Connect NeOse Advance to start'}
      </Typography.Title>
      <FlexRow
        style={{
          justifyContent: 'center',
        }}
      >
        <PairAndConnectivityWidget
          name="CoreSensor Module over BLE"
          isConnected={csmIsConnected}
          isPaired={isPaired}
          onPair={async () => {
            try {
              let device = await requestBleDevice();
              try {
                let service = await getBleService(device);
                if (!service) {
                  return;
                }
                setIsPaired(true);
                setCSMBleDevice(device);
                setCSMIsConnected(true);
              } catch (e: any) {
                console.log('error getting service', e.message);
              }
            } catch (e) { }
          }}
        />
      </FlexRow>
      <FlexCol
        style={{
          marginTop: 50,
          textAlign: 'center',
          maxWidth: '80%',
        }}
      >
        <i>
          <span>
            Hint: If you are using the app for the <b>first time</b>, click on <SwapOutlined /> button to your PC to the device
          </span>
          <br />
          <span>
            You should pair <b>Aryballe Neose BLE</b> device
          </span>
        </i>
      </FlexCol>
      <FlexCol
        style={{
          marginTop: 20,
        }}
      >
        <Button
          onClick={async () => {
            if (csmBleDevice && csmBleDevice.gatt && csmBleDevice.gatt.connected) {
              void csmBleDevice.gatt.disconnect();
            }

            if (csmBleDevice && csmBleDevice.forget) {
              void (await csmBleDevice.forget());
            }
            setIsPaired(false);
            setCSMIsConnected(false);
            setCSMBleDevice(undefined);
          }}
        >
          Disconnect
        </Button>



      </FlexCol>
    </FlexCol>
  );
};
