import { CSSProperties, FC, useEffect, useState } from 'react';
import './App.css';
import 'antd/dist/reset.css';
import 'react-json-view-lite/dist/index.css';
import { ConfigProvider, Layout, Menu, theme } from 'antd';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
import { Link, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { ControlOutlined, FolderOpenOutlined, FundViewOutlined, HomeOutlined, SwapOutlined } from '@ant-design/icons';
import { ThimphuMessageDispatcher } from './components/dispatchers/thimphuMessageDispatcher';
import { FlexCol, FlexRow } from './components/common';
import { RefkitMessageDispatcher } from './components/dispatchers/refkitMessageDispatcher';
import { SerialDevicePage } from './components/pages/serialDevicePage';
import { SerialConnectPage } from './components/pages/serialConnectPage';
import { SerialSensePage } from './components/pages/serialSensePage';
import { RecordsPage } from './components/pages/recordsPage';
import { RecordPage } from './components/pages/recordPage';
import { SerialDebugPage } from './components/pages/serialDebugPage';
import { HomePage } from './components/pages/homePage';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoginWidget } from './components/pages/loginPage';
import OktaAuth, { toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security } from '@okta/okta-react';
import { oidcConfig } from './components/auth';
import { ProfilePage } from './components/pages/profilePage';
import { BleDebugPage } from './components/pages/bleDebugPage';
import { CSMBleMessageDispatcher } from './components/dispatchers/csmBleMessageDispatcher';
import { BleConnectPage } from './components/pages/bleConnectPage';
import { BleSensePage } from './components/pages/bleSensePage';
import { BleDevicePage } from './components/pages/bleDevicePage';
import { isBleApp, useMediaQuery } from './components/utils';
import OdorIdentification from './components/pages/OdorIdentification/SerialOdorIdentificationContainer';
import SerialOdorIdentification from './components/pages/OdorIdentification/SerialOdorIdentificationContainer';
import BleOdorIdentification from './components/pages/OdorIdentification/BleOdorIdentificationContainer';
import Monitoring from './components/pages/monitoring';
import RecordsAndModelsPage from './components/pages/RecordsAndModelsPage';

const SIDER_WIDTH = 250;
const SIDER_COLLAPSED_WIDTH = 80;

const AppMenu: FC<{
  isCollapsed: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
}> = ({ isCollapsed, setIsCollapsed }) => {
  const isMobile = useMediaQuery();

  return (
    <Sider
      style={
        isMobile
          ? {
            overflow: 'auto',
            position: 'fixed',
            width: '100%',
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1000,
          }
          : {
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
          }
      }
      collapsible={isMobile ? false : true}
      collapsed={isCollapsed}
      onCollapse={(collapsed) => {
        setIsCollapsed(collapsed);
      }}
      collapsedWidth={isMobile ? '100%' : undefined}
      width={isMobile ? '100%' : SIDER_WIDTH}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: isMobile ? 'row' : 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          gap: 0,
        }}
      >
        {!isMobile && (
          <FlexCol
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              gap: 0,
            }}
          >
            <FlexRow
              style={{
                height: '64px',
                justifyContent: 'center',
              }}
            >
              {isCollapsed ? (
                <img
                  alt="logo"
                  src="/images/logo292.png"
                  style={{
                    padding: 10,
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                />
              ) : (
                <img
                  alt="logo"
                  src="/images/aryballe-tm-white-transparent.png"
                  style={{
                    padding: 5,
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                />
              )}
            </FlexRow>
            {!isCollapsed && <span style={{ color: '#eee' }}>NeOse WebApp</span>}
          </FlexCol>
        )}
        <Menu
          theme="dark"
          mode={isMobile ? 'horizontal' : 'vertical'}
          style={{
            width: '100%',
          }}
          defaultActiveFirst
          items={[
            {
              key: 'menu-key-home',
              icon: <HomeOutlined />,
              label: <Link to="/">Home</Link>,
            },
            {
              key: 'menu-key-sense',
              icon: <FundViewOutlined />,
              label: <Link to={`/sense/${isBleApp() ? 'ble' : 'serial'}`}>Sense</Link>,
            },
            {
              key: 'menu-key-records',
              icon: <FolderOpenOutlined />,
              label: <Link to={`/records`}>Records</Link>,
            },
            {
              key: 'menu-key-device',
              icon: <ControlOutlined />,
              label: <Link to={`/device/${isBleApp() ? 'ble' : 'serial'}`}>Device</Link>,
            },
          ]}
        />
        <FlexRow
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            gap: 0,
          }}
        >
          {(function () {
            if (isBleApp()) {
              return (
                <div>
                  <CSMBleMessageDispatcher minimized={isCollapsed} />
                </div>
              );
            } else {
              return (
                <>
                  <div
                    style={{
                      borderTop: '1px solid #ccc',
                      borderRight: '1px solid #ccc',
                    }}
                  >
                    <RefkitMessageDispatcher minimized={isCollapsed} />
                  </div>
                  <div
                    style={{
                      borderTop: '1px solid #ccc',
                    }}
                  >
                    <ThimphuMessageDispatcher minimized={isCollapsed} />
                  </div>
                </>
              );
            }
          })()}
        </FlexRow>
      </div>
    </Sider>
  );
};

function App() {
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    window.addEventListener('DOMContentLoaded', () => {
      let displayMode = 'browser-tab';
      if (window.matchMedia('(display-mode: standalone)').matches) {
        displayMode = 'standalone';
      }
      console.log('app root: display mode is', displayMode);
      if (displayMode === 'browser-tab') {
        toast("You can install this app on your device by clicking on the 'Add to Home Screen' button in your browser menu.");
      }
    });

    // return () => {
    //   window.removeEventListener('DOMContentLoaded', () => {})
    // }
  }, []);

  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    history.replace(toRelativeUrl(originalUri || '', window.location.origin));
  };

  const isMobile = useMediaQuery();

  const minHeightOffset = isMobile ? 200 : 114;

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Security oktaAuth={new OktaAuth(oidcConfig)} restoreOriginalUri={restoreOriginalUri}>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
          token: {
            colorPrimary: 'rgb(32, 42, 68)',
            colorLink: 'rgb(32, 42, 68)',
            fontFamily: 'Century Gothic',
            fontSize: 13,
            colorTextBase: '#222',

            // These are the colors of table selection
            colorPrimaryBg: 'rgba(32, 42, 68, 0.2)',
            colorPrimaryBgHover: 'rgba(32, 42, 68, 0.4)',
          },
        }}
      >
        <Layout>
          <AppMenu isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
          <Layout
            className="site-layout"
            style={{
              marginLeft: isMobile ? 0 : isCollapsed ? SIDER_COLLAPSED_WIDTH : SIDER_WIDTH,
            }}
          >
            <Header
              style={{
                padding: 0,
                background: colorBgContainer,
                position: 'sticky',
                top: 0,
                zIndex: 500,
              }}
            >
              <FlexRow
                style={{
                  justifyContent: isMobile ? 'space-between' : 'end',
                  alignItems: 'center',
                  height: '100%',
                  paddingRight: 20,
                }}
              >
                {isMobile && (
                  <FlexCol
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 0,
                    }}
                  >
                    <FlexRow
                      style={{
                        height: '64px',
                        justifyContent: 'center',
                      }}
                    >
                      <img
                        alt="logo"
                        src="/images/logo292.png"
                        style={{
                          padding: 10,
                          maxWidth: '100%',
                          maxHeight: '100%',
                        }}
                      />
                    </FlexRow>
                  </FlexCol>
                )}
                <LoginWidget />
              </FlexRow>
            </Header>

            <Content
              style={{
                marginTop: 16,
                marginLeft: 16,
                marginRight: 16,
                marginBottom: 24,
                padding: isMobile ? 0 : 24,
                overflow: 'initial',
                minHeight: `calc(100vh - ${minHeightOffset}px)`,
              }}
            >
              <Switch>
                <Route path="/signin/callback" component={LoginCallback} />
                <Route path="/signout/callback">
                  <Redirect to="/" />
                </Route>

                <Route path="/profile">
                  <ProfilePage />
                </Route>

                <Route path="/device/serial">
                  <SerialDevicePage />
                </Route>

                <Route path="/device/ble">
                  <BleDevicePage />
                </Route>

                <Route path="/connect/serial">
                  <SerialConnectPage />
                </Route>

                <Route path="/connect/ble">
                  <BleConnectPage />
                </Route>

                <Route path="/odorIdentification/serial">
                  <SerialOdorIdentification />
                </Route>

                <Route path="/odorIdentification/ble">
                  <BleOdorIdentification />
                </Route>

                <Route path="/Monitoring/ble">
                  <Monitoring />
                </Route>

                <Route path="/sense/serial">
                  <SerialSensePage />
                </Route>

                <Route path="/sense/ble">
                  <BleSensePage />
                </Route>

                <Route path="/records/:recordId">
                  <RecordPage />
                </Route>

                <Route path="/records">
                  <RecordsAndModelsPage />
                </Route>

                <Route path="/debug/serial">
                  <SerialDebugPage />
                </Route>

                <Route path="/debug/ble">
                  <BleDebugPage />
                </Route>

                <Route path="/">
                  <HomePage />
                </Route>
              </Switch>
            </Content>
            <Footer
              style={{
                textAlign: 'center',
                height: '50px',
              }}
            >
              © Aryballe {new Date().getFullYear()} — ver. <b>{process.env.REACT_APP_VER != null && `${process.env.REACT_APP_VER}`}</b> {process.env.REACT_APP_ENV != null && `[${process.env.REACT_APP_ENV.toUpperCase()}]`}
            </Footer>
          </Layout>
        </Layout>
        <ToastContainer />
      </ConfigProvider>
    </Security>
  );
}

export default App;
